<template>
    <div class="checkbox" :class="[theme, {'active': active, 'disabled': disabled, 'wrap': wrap}]">
        <label v-for="item in data" :data-value="item.displayValue || item.value" v-ga:clickable="item.value" :class="{'checked': checked(item.value)}">
            <input  type="checkbox" :value="item.value" :disabled="item.disabled" :checked="checked(item.value, item.defaultValue)" @change="changed"/>
            <i></i>
            <span v-html="item.text"></span>
        </label>
    </div>
</template>
<script src="./mvt-checkbox.js"></script>
