import { mapGetters, mapActions } from 'vuex';
import mvtPopSignin from '@/common/components/mvtpopsignin/mvt-popsignin.vue';
import homeWelcome from '@/home/components/homewelcome/home-welcome.vue';
import homeNearbyViewed from '@/home/components/homenearbyviewed/home-nearbyviewed.vue';
import homeNearbyReduce from '@/home/components/homenearbyreduce/home-nearbyreduce.vue';
import homeNearbyOpen from '@/home/components/homenearbyopen/home-nearbyopen.vue';
import homeNearbyNew from '@/home/components/homenearbynew/home-nearbynew.vue';
import homeSavedSearch from '@/home/components/homesavedsearch/home-savedsearch.vue';
import homeLinks from '@/home/components/homelinks/home-links.vue';
import homeQRCode from '@/home/components/homeqrcode/home-qrcode.vue';
import homeHotlead from '@/home/components/homehotlead/home-hotlead.vue';
import homeNavigation from '@/home/components/homenavigation/home-navigation.vue';
import mvtNearby from '@/common/components/mvtnearby/mvt-nearby.vue';
import mvtHotleadForm from '@/common/components/mvthotleadform/mvt-hotleadform.vue';
import mvtPicture from '@/common/components/mvtpicture/mvt-picture.vue';
import mvtClientOnly from '@/common/components/mvtclientonly/mvt-clientonly.js';
import lazyCarouselCheckMixin from '@/common/mixins/lazycarouselcheck';
import enJSON from '@/common/utilities/lang/en/home.js';
import esJSON from '@/common/utilities/lang/es/home.js';
import mvtDialogCenter from '@/common/components/mvtdialogcenter/mvt-dialogcenter.vue';
import mvtHeader from '@/common/components/mvtheader/mvt-header.vue';
import mvtFooter from '@/common/components/mvtfooter/mvt-footer.vue';
import homeRadioBanner from '@/home/components/homeradiobanner/home-radioBanner.vue'
import { getGa4Trigger } from '../../../../widget/spec/ga4helper';

export default {
    name: 'pageHome',
    inject: ['$eventBus'],
    components: {
        mvtPopSignin,
        mvtDialogCenter,
        homeWelcome,
        homeLinks,
        homeQRCode,
        homeHotlead,
        homeNavigation,
        homeNearbyViewed,
        homeNearbyReduce,
        homeNearbyOpen,
        homeNearbyNew,
        homeSavedSearch,
        mvtNearby,
        mvtHotleadForm,
        mvtPicture,
        mvtClientOnly,
        mvtHeader,
        mvtFooter,
        homeRadioBanner,
    },
    mixins: [lazyCarouselCheckMixin],
    mounted() {
        this.hasRedirectedLogin();
        this.$eventBus.$emit('eventbus-pageLoaded');
        const queryObj = $.parseQueryString();
        const isGoogleRedirect = location.href.match(/idToken=/);
        if (isGoogleRedirect) {
            if (queryObj.idToken) {
                var data = {
                    credential: queryObj.idToken,
                    clientId: this.glb.googleLoginClientId,
                    userType: this.glb.pageInfo.userType,
                    trigger: getGa4Trigger($.getStorage('googleLoginTrigger'), $.getStorage('googleLoginRequestName', this.requestName)),
                };
                var that = this;
                this.loginForGoogleAccount(data).then(function (res) {
                    $.setStorage('isGoogleLogin', true);
                    that.$eventBus.$emit('eventbus-context-update');
                    that.afterGoogleLogin();
                });
            } else {
                this.afterGoogleLogin();
            }
        }
        this.checkForConciergeRequest();
        if (this.$route.query.confirm_cer) {
            this.$eventBus.$emit('dialog-center', {
                name: 'mvtConfirmCER',
                trigger: 'mvtConfirmCER',
                opt: {},
            });
        }
    },
    methods: {
        ...mapActions('glb', ['loginForGoogleAccount']),
        hasRedirectedLogin: function () {
            const qs = this.$route.query;
            if (qs && qs.showlogin) {
                const redirectUrl = qs.returnurl;
                if (this.glb.user.id) {
                    redirectUrl && (location.href = redirectUrl);
                } else {
                    this.$eventBus.$emit('dialog-center', { name: 'mvtAuthenticate', opt: { type: 'mvtLogin', redirectUrl }, trigger: this.$options.name });
                }
            }
        },
        show3rdPartyForm(id, url) {
            this.$eventBus.$emit('eventbus-pop-3rdparty', {
                id: id,
                url: url,
                trigger: this.$options.name,
            });
        },
        afterGoogleLogin() {
            let urlToRedirect = $.getStorage('googleRedirectUrl') || '';
            $.removeStorage('googleRedirectUrl');
            setTimeout(() => {
                window.open(urlToRedirect, '_self');
            }, 500);
        },
        openContactUs() {
            this.$eventBus.$emit('dialog-open-contactus');
        },
        checkForConciergeRequest() {
            var search = window.location.search;
            if (search && search.indexOf('popconciergerequest=1') > -1) {
                this.$eventBus.$emit('dialog-center', {
                    name: 'myConcierge',
                    opt: { popconciergerequest: true },
                    trigger: this.$options.name,
                });
            }
        },
    },
    computed: {
        ...mapGetters('glb', ['viewport', 'glb', 'getSplit']),
        ...mapGetters('home', ['home']),
        isGoMovotoCampaign() {
            const campaign = this.glb.utm_campaign;
            const medium = this.glb.utm_medium;
            const source = this.glb.utm_source;
            return campaign === 'iheartflight1' &&
                    medium === 'paid' &&
                    source === 'radioorpodcast';
        }
    },
    beforeCreate() {
        this.injectMolangDataStore({ type: 'home', en: enJSON, es: esJSON });
    },
};
