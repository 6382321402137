import {mapGetters} from 'vuex';

export default {
  name: 'mvtPicture',
  inject: ['$eventBus'],
  props: {
    src: {
      // for *.jpg/jpeg/png/gif/bmp image path
      type: String,
      required: true
    },
    srcset: {
      // for *.webp image path
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters('glb', ['glb']),
    useWebpImg() {
      return $.browserType() !== 'safari' || this.glb.OS !== 'iOS';
    }
  }
};
