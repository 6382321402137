<script>
  import { h } from 'vue'
  export default {
    props: ['text'],
    setup(props) {
      return () =>
        h('script', {
          type: "application/ld+json",
          innerHTML: props.text,
        });
    },
  }
</script>