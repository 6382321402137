<template>
    <section class="radio-banner">
        <img :src="glb.cdnUrl + 'images/desktop/home/ads-banner1.svg'" alt="Banner svg vector 1">
        <img :src="glb.cdnUrl + 'images/desktop/home/ads-banner2.svg'" alt="Banner svg vector 2">
        <img :src="glb.cdnUrl + 'images/desktop/home/ads-banner3.svg'" alt="Banner svg vector 3">
        <div class="flex middle center xs-grid-cols-1">
            <img class="hidden-xs" :src="glb.cdnUrl + 'images/desktop/home/heard-on-radio.png'" alt="Heard on radio" />
            <img class="hidden-md hidden-sm hidden-lg" :src="glb.cdnUrl + 'images/desktop/home/heard-on-radio-mobile.png'" alt="Heard on radio mobile" />
            <span class="text-white text-center-xs f6">Movoto simplifies home buying and selling with personalized guidance- go Movoto!</span>
            <div class="banner-actions flex">
                <a role="button" class="btn small btn-1" :href="glb.appUrl + 'for-sale/'">Browse homes</a>
                <a role="button" class="btn secondary small btn-2" :href="glb.appUrl + 'sell/'">Get a cash offer</a>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'homeRadioBanner',
    computed: {
        ...mapGetters('glb', ['glb']),
    },
};
</script>

<style lang="scss">
    @import "./home-radioBanner.scss";
</style>
