import { mapGetters } from 'vuex';
import mvtNearby from '@/common/components/mvtnearby/mvt-nearby.vue';
import mvtLazyload from '@/common/components/mvtlazyload/mvt-lazyload.vue';

export default {
    name: 'homeNearbyReduce',
    components: {
        mvtNearby,
        mvtLazyload,
    },
    computed: {
        ...mapGetters('glb', ['glb', 'getCityReducedListings']),
        ...mapGetters('home', ['home']),
        cityState() {
            return this.home.geo.city + ' ' + this.home.geo.state;
        },
    },
    methods: {
        completed() {
            this.$refs.lazyload.ready();
        },
    },
};
