<template>
    <section>
        <ul class="flex vertical-xs fixed center">
            <li>
                <a :href="glb.appUrl + 'guide/'">
                    {{molang('common.neighborhoodguide')}}
                </a>
            </li>
            <li>
                <a :href="glb.appUrl + 'market-trends/'">
                    {{molang('common.markettrend')}}
                </a>
            </li>
            <li>
                <a :href="glb.appUrl + 'schools/'">
                    {{molang('common.schools')}}
                </a>
            </li>
            <li>
                <a :href="glb.appUrl + 'blog/category/tips-how-tos/'">
                    {{molang('common.realestatetips')}}
                </a>
            </li>
        </ul>
    </section>
</template>

<script src="./home-navigation.js"></script>
