export default {
    "WelcomeHome": "Bienvenido a casa",
    "WelcomeHomeNew": "Compra, vende y posee con confianza",
    "WhoWeAre": "Para Compradores",
    "WhoWeAreText": "Nuestro enfoque se basa en una comprensión profunda de sus necesidades. Lo apoyamos con herramientas, orientación experta y recursos personalizados, luego lo conectamos con un agente local, para que tenga confianza y esté equipado para cada paso.",
    "WhatWeDo": "Para Vendedores",
    "WhatWeDoText": "Las Agentes te proporcionará el conocimiento que necesitas para monitorear el valor de tu casa, identificar cuándo vender y aprovechar al máximo la venta de tu propiedad. Ofrecemos asesoría transparente por medio de datos y una selección de agentes personalizada según las necesidades de cada cliente.",
    "WhyWorkWithUs": "Para Dueños de Casas",
    "WhyWorkWithUsText": "Con asesoramiento personalizado y herramientas inteligentes, podrás tomar decisiones informadas sobre el patrimonio más importante de tu vida: tu casa. Monitoriza fácilmente el índice de valor de tu propiedad, ahorra en impuestos, hipotecas y seguros, y crea un bienestar a largo plazo.",
    "AllInOnePlace": "Todo en un lugar",
    "AllInOnePlaceTitle": "Su ventanilla única para encontrar la casa de sus sueños",
    "AllInOnePlaceText": "Únase a los 350.000 usuarios diarios que han confiado en Movoto para encontrar el hogar perfecto. Busque propiedades, vecindarios, comparables, jornadas de puertas abiertas y más, todo en un solo lugar.",
    "SearchForHomes": "Busca casas",
    "ConnectAgent": "Conectar a una agente",
    "ConnectAgentTitle": "Programe una cita gratuita y sin compromiso",
    "ConnectAgentText": "Deje que uno de nuestros principales agentes locales le ayude a comprar o vender una casa. Examinamos a todos nuestros agentes para asegurarnos de que tengan la experiencia del mercado y el impulso para que la transacción de su casa sea un éxito.",
    "FindAnAgent": "Encontrar una agente",
    "HomeValuation": "Obtenga una valoración de vivienda",
    "HomeValuationTitle": "Comprenda el valor de su propiedad",
    "HomeValuationText": "Nuestros algoritmos actualizados recientemente garantizan que estemos obteniendo los comparables más relevantes y los datos de mercado más recientes para brindarle la valoración más precisa para su hogar.",
    "AreYouAgent": "Eres agente inmobiliario?",
    "AreYouAgentTitle": "Únase a la red OJO Select y comience a trabajar con más clientes hoy",
    "AreYouAgentText": "Si es un agente de alto rendimiento con una gran trayectoria en el cuidado de sus clientes, nos encantaría presentarle compradores y vendedores motivados.",
    "ApplyNow": "Aplica ya",
    "Testimonial": "Testimonial",
    "GetStarted": "Empieza ahora",
    "SeeHomes": "Búsqueda de Propiedades",
    "ConnectwithanAgent": "Conectate con un Agente",
    "ViewOwnerDashboard": "Tablero de Propetario",
    "TestimonialTitle": "Lo que dicen las clientes",
    "TestimonialText": "Frank Wong, nuestro agente de Movoto del área de la bahía de Oakland, explica cómo ayudó a su cliente a vender su casa existente y comprar una nueva mientras negociaba $ 7500 en créditos para constructores. La casa original de sus clientes tuvo una oferta en 7 días.",
    "buy": "Comprar",
    "buyAHome": "Comprar una casa",
    "BuyText": "Ciudad, código postal, escuela, dirección, vecindario...",
    "BuyTip": "Movoto proporciona acceso rápido a propiedades, información sobre el mercado y expertos en bienes raíces.",
    "Recently": "Recientemente",
    "Sold": "Vendido",
    "SoldText": "¿En qué área está usted interesado?",
    "SoldTip": "Movoto impulsó 387 millones de búsquedas de casas en 2020",
    "PriceMyHome": "Precio de Mi Casa",
    "PriceMyHomeText": "Ingrese dirección de casa...",
    "sellMyHomeText": "mi dirección de casa",
    "PriceMyHomeTip": "Los agentes de Movoto están en el 5% superior de sus mercados locales.",
    "NewestTitle": "ANUNCIOS RECIENTES ",
    "NewestDesp": "Listados recientes en",
    "NewestDespRentals": "Alquileres nuevos en",
    "MovotoOjo1": "Movoto está emocionado de unirse a OJO Labs",
    "MovotoOjo2": "untos transformaremos la forma en que las personas compran y venden casas para siempre ",
    "MovotoBotText": "LISTADOS INMOBILIARIOS MOVOTO",
    "MovotoBotTitle": "Bienvenido a casa",
    "RealEstateListings": "LISTADOS INMOBILIARIOS",
    "OpenDesp": "Casas en muestra en",
    "ReduceDesp": "Casas a bajo precio en",
    "RecentTitle": "MIS HOGARES",
    "RecentDesp": "Visto Recientemente",
    "HotleadTitle": "Habla con un agente",
    "hotleadSub": "Lo pondremos en contacto con un agente de primera categoría, que se adapte a sus necesidades de viaje en casa.",
    "HotleadButtionCap": "Conectate",
    "pageTitle": "Movoto: casas en venta, listados, agentes, valoración de viviendas, venta de su casa",
    "pageDesp": "Movoto Real Estate: La fuente más confiable para ayudarlo a comprar o vender una casa en la MLS. Busque casas en venta y aprenda sobre las escuelas locales, la demografía y las tendencias del mercado.",
    "NewListings": "Nuevos listados por estado",
    "ListingsByState": "Listados por estado",
    "County": "Condado",
    "AllRealEstate": "Todos los listados por estado",
    "PopularCities": "Ciudades Populares",
    "RealEstate": "Bienes Raíces",
    "searchPlaceholder": "Ingrese una dirección de casa",
    "learnMore": "Aprende más",
    "homeEstimate": "Valoración de vivienda",
    "homeEst": "Estimación",
    "sell": "Vender",
    "sellHome": "Vender mi casa",
    "sellOffer": "Vender / Oferta en efectivo",
}