<template>
    <div class="mvt-player" v-if="_data && _data.length">
        <img fetchpriority="auto" loading="lazy" ref="img1" class="img1" :src="img1Src" :alt="alt" @animationiteration="onFirstImgAnimationEnd">
        <img fetchpriority="auto"  v-if="!lazyload || loading>0 " class="img2"  loading="lazy" ref="img2" :src="img2Src" :alt="alt" @animationiteration="onLastImgAnimationEnd">
        <div class="buttons" v-if="showControl">
            <button type="button" @click.stop="prev"><i class="icon-angle-left"></i></button>
            <button type="button" @click.stop="toggle"><i :class="isPaused? 'icon-play' : 'icon-equals'"></i></button>
            <button type="button" @click.stop="next"><i class="icon-angle-right"></i></button>
        </div>
        <div class="preload" v-if="_data && _data.length>2" >
            <img fetchpriority="auto" loading="lazy" ref="repload" :src="preloadImg" alt="Slideshow Preload Image">
        </div>
        <div class="mvt-player-processing" v-if="showProcess">
            <button @click="goTo(i)" :class="i===index ? 'active' : ''" v-for="(item, i) in _data"></button>
        </div>
    </div>
</template>
<script src="./mvt-player1.js"></script>
<style lang="scss">
@import "./mvt-player1.scss";
</style>
