<template>
    <div class="mvt-shifter" :class="[theme, {'center':center}]">
        <button type="button" aria-label="Previous" v-if="showArrow" :class="{'disable':!hasPrev}" @click="onPrev" class="arrow prev"><i class="icon-angle-left"></i></button>
        <div class="mvt-shifter-list" ref="scroller" data-role="scroller">
            <slot></slot>
        </div>
        <button type="button" aria-label="Next" v-if="showArrow" :class="{'disable':!hasNext}" @click="onNext" class="arrow next"><i class="icon-angle-right"></i></button>
    </div>
</template>

<script>
export default {
    name: 'MvtShifterPresentational',
    props: {
      center: Boolean,
      showArrow: Boolean,
      hasPrev: Boolean,
      hasNext: Boolean,
      onPrev: Function,
      onNext: Function,
      theme: String
    },
};
</script>
