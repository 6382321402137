<template>
    <section>
        <div class="grid fixed xs-grid-cols-1">
            <div class="text-center">
                <h2 class="f3 text-bold">
                    {{molang('home.RecentDesp')}}
                </h2>
            </div>
            <mvtLazyload ref="lazyload" data-role="propertycard">
                <mvtNearby type="viewed"  @completed="completed" carousalType="viewed"></mvtNearby>
            </mvtLazyload>
        </div>
    </section>
</template>

<script src="./home-nearbyviewed.js"></script>
