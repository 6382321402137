import format from './format.js';

const isSSR = typeof window === 'undefined';

function getPropertyTypeDisplay (data){
    if(data.isRentals) {
        if(data.propertyType === 'SINGLE_FAMILY_HOUSE'){
            return 'House';
        }else if(data.propertyType === 'CONDO' || data.propertyType === 'MULTI_FAMILY'){
            return 'Apartment';
        }
    }
    if(data.propertyType === 'SINGLE_FAMILY_HOUSE'){
        return 'House';
    }else if(data.propertyType === 'MULTI_FAMILY'){
        return 'Multi-family';
    }else if(data.propertyType === 'COMMERCIAL'){
        return 'Commercial';
    }else if(data.propertyType === 'LAND'){
        return 'Lot/Land';
    }else if(data.propertyType === 'MOBILE' || data.propertyType === 'CONDO'){
        return data.propertyTypeDisplayName || data.propertyType;
    }
    return 'Other';
}

function populatePRDescription(dpp) {
    if (!dpp) {
        return '';
    }
    const price = dpp.nearbyEstPrice ? dpp.nearbyEstPrice.estPrice : dpp.price;
    const pricePerArea = dpp.nearbyEstPrice ? dpp.nearbyEstPrice.areaPriceAvg :dpp.pricePerArea;
    const marketData = dpp.marketTrendTable && (dpp.marketTrendTable.neighborhood || dpp.marketTrendTable.zipcode || dpp.marketTrendTable.city);
    let desc = `Built in ${dpp.yearBuilt || (dpp.publicRecord && dpp.publicRecord.yearBuilt) || '-'}, this ${dpp.bed || '-'} bedroom${dpp.bed === 1 ? '' : 's'}, ${dpp.bath || '-'} bathroom${dpp.bathroom === 1 ? '' : 's'} ${dpp.propertyTypeDisplay} property at ${dpp.geo.formatAddress} is approximately ${dpp.sqftTotal || '-'} square feet. Movoto's Comparative Market Estimated Value is $${format.formatNumberByComma(price)} with a value per Sqft of $${format.formatNumberByComma(pricePerArea)}. ${dpp.geo.address} is located ${dpp.geo.neighborhood ? `in the ${dpp.geo.neighborhood} neighborhood`: ''}`;
    if (dpp.schoolDistricts && dpp.schoolDistricts.length) {
        desc += ` in the ${dpp.schoolDistricts[0].name}`;
    }
    desc += '.';
    if (dpp.nearbySchools && dpp.nearbySchools.length) {
        desc += ` The closest school is ${dpp.nearbySchools[0].name}.`;
    }
    if (marketData) {
        desc += ` <br/>The median days on the market for properties in this area is ${marketData.allTypeMedianDom} with a median list price of $${format.formatNumberByComma(marketData.allTypeMedianListPrice)} and median cost per square foot of $${format.formatNumberByComma(marketData.allTypeMedianPricePerSqftHouse)}.`;
    }
    desc += ' This property is currently off-market.';
    return desc;
}

function populatePRDescriptionNew(dpp) {
    if (!dpp) {
        return '';
    }

    const lastSold = dpp.priceHistory && dpp.priceHistory.length && dpp.priceHistory.find( item => {return item.status === 'Sold'});
    const price = dpp.nearbyEstPrice ? dpp.nearbyEstPrice.estPrice : dpp.price;
    const pricePerArea = dpp.nearbyEstPrice ? dpp.nearbyEstPrice.areaPriceAvg :dpp.pricePerArea;
    const marketData = dpp.marketTrendTable && (dpp.marketTrendTable.city || dpp.marketTrendTable.zipcode);

    let newDesc = `Located at ${dpp.geo.address} in the city of ${dpp.geo.city}, this ${dpp.propertyTypeDisplay && dpp.propertyTypeDisplay.toLowerCase()} features ${dpp.bed || '-'} bedrooms, ${dpp.bath || '-'} bathrooms, ${format.formatNumberByComma(dpp.sqftTotal)} square feet, on a ${format.formatNumberByComma(dpp.lotSize)} square feet lot.`
    newDesc += ` The property is located ${dpp.geo.neighborhood ? `in the ${dpp.geo.neighborhood} neighborhood`: ''}`
    if (dpp.schoolDistricts && dpp.schoolDistricts.length) {
        newDesc += ` within the ${dpp.schoolDistricts[0].name}`;
    }
    if (dpp.nearbySchools && dpp.nearbySchools.length) {
        newDesc += ` and is in proximity to ${dpp.nearbySchools[0].name}`;
    }
    newDesc += '.';

    newDesc += ` <br/><br/>Based on Movoto’s Comparative Market Estimated Value, the property holds an assessed worth of $${format.formatNumberByComma(price)} ($${format.formatNumberByComma(pricePerArea)}/Sqft).`;
    if(lastSold) {
        newDesc += ` This property last sold for $${format.formatNumberByComma(lastSold.price)} on ${lastSold.dateDesktop}.`;
    }
    if (marketData) {
        newDesc += ` Properties in this region typically spend ${marketData.allTypeMedianDom} days on the market.`;
        newDesc += ` The median list price for the area is $${format.formatNumberByComma(marketData.allTypeMedianListPrice)} ($${format.formatNumberByComma(marketData.allTypeMedianPricePerSqftHouse)}/Sqft).`;
    }
    return newDesc;
}

function populatePRMetaDescription(dpp) {
    if (!dpp) {
        return '';
    }
    let desc='';
    if(dpp.propertyType==='LAND'){
        desc = `${dpp.geo.address.length ? `${dpp.geo.address} is a` : 'A'} ${format.formatFriendlyLotSize(dpp.lotSize,dpp.lotSizeUnit)} land located ${dpp.geo.neighborhood ? `in the ${dpp.geo.neighborhood} neighborhood` : ''} in ${dpp.geo.city}. View the property estimate, details, and search for more land and homes nearby on Movoto.`;
    }else{
        desc = `${dpp.geo.address.length ? `${dpp.geo.address} is a` : 'A'} ${dpp.bed ? `${dpp.bed} bed${dpp.bed === 1 ? '' : 's'}, `:''}${dpp.bath ? `${dpp.bath} bath${dpp.bath === 1 ? '' : 's'}, `:''}${dpp.sqftTotal ? ` ${format.formatNumberByComma(dpp.sqftTotal)} Sqft` : ''} ${getPropertyTypeDisplay(dpp).toLowerCase()}${dpp.yearBuilt ? ` built in ${dpp.yearBuilt}` : ''}${dpp.lotSize ? ` on a ${format.formatFriendlyLotSize(dpp.lotSize,dpp.lotSizeUnit)} lot` : ''} and located ${(dpp.geo.neighborhood || dpp.geo.neighborhoodName) ? `in the ${(dpp.geo.neighborhood || dpp.geo.neighborhoodName)} neighborhood` : ''} in ${dpp.geo.city}. View the property estimate, details, and search for more land and homes nearby on Movoto.`
    }
    return desc;
}

function getFields(dpp) {
    var tmp = [];
    var isPrOnly = dpp.isPrOnly;
    var isLand = dpp.isLand;
    if (!isPrOnly) {
        tmp.push({
            key: 'price',
            title: 'List Price'
        });
    }
    if (!isLand) {
        tmp = tmp.concat([{
            key: 'bed',
            title: 'Bed'
        },
        {
            key: 'bath',
            title: 'Bath'
        }, {
            key: 'area',
            title: 'Home Size'
        }]);
    }
    if (dpp.isCondo) {
        tmp.push({
            key: 'hoafee',
            title: 'HOA Fee'
        });
    } else {
        tmp.push({
            key: 'lotSize',
            title: 'Lot Size'
        });
    }
    if (!isLand && !isPrOnly) {
        tmp.push({
            key: 'pricePerArea',
            title: '$/Sqft'
        });
        tmp.push({
            key: 'yearBuilt',
            title: 'Year Built'
        });
    }
    return tmp;
}

function compareClosestPropertiesLight(dpp, nearbyHome, fields = []) {
    if (!(nearbyHome && nearbyHome.length)) {
        return [];
    }

    var tmp = fields.map(function (field) {
        var key = field.key;
        var currentValue = dpp[key];
        var notFalsyFiltered = key === 'bed';
        var valueList = nearbyHome.reduce(function (accum, e) {
            if (e[key] || notFalsyFiltered) {
                accum.push(e[key]);
            }
            return accum;
        }, []);
        if (currentValue || notFalsyFiltered) {
            valueList.push(currentValue);
        }
        var maxValue = Math.max.apply(this, valueList);
        var minValue = Math.min.apply(this, valueList);
        if (maxValue === minValue) {
            return null;
        }
        var total = 0;
        for (var i = 0; i < valueList.length; i++) {
            total += valueList[i];
        }
        var avg = total / valueList.length;
        var percentage = Math.round((currentValue - avg) / avg * 100);

        if (key === 'bath' || key === 'bed') {
            avg = parseFloat(avg).toFixed(2);
        } else {
            avg = Math.round(avg);
        }

        let priceDiff = ''
        if (key === 'price') {
            // Nearest ten thousand
            priceDiff = Math.abs(avg - currentValue)
            priceDiff = priceDiff > 10000 ? Math.round(priceDiff/10000)*10000 : priceDiff
        }

        return {
            key: field.key,
            avg: avg,
            percentage: Math.abs(percentage),
            value: currentValue,
            priceDiff: priceDiff ? '$' + format.friendlyPrice(priceDiff) : null
        };
    });
    return tmp;
}

function compareClosestProperties(dpp, nearbyHome, fields = []) {
    if (!(nearbyHome && nearbyHome.length)) {
        return [];
    }

    var tmp = fields.map(function (field) {
        var key = field.key;
        var currentValue = dpp[key];
        var notFalsyFiltered = key === 'bed';
        var valueList = nearbyHome.reduce(function (accum, e) {
            if (e[key] || notFalsyFiltered) {
                accum.push(e[key]);
            }
            return accum;
        }, []);
        if (currentValue || notFalsyFiltered) {
            valueList.push(currentValue);
        }
        var maxValue = Math.max.apply(this, valueList);
        var minValue = Math.min.apply(this, valueList);
        if (maxValue === minValue) {
            return null;
        }
        var total = 0;
        for (var i = 0; i < valueList.length; i++) {
            total += valueList[i];
        }
        var avg = total / valueList.length;
        var percentage = (key === 'yearBuilt') ? Math.round(currentValue - avg) : Math.round((currentValue - avg) / avg * 100);
        var left = 50;

        if (currentValue > avg) {
            left = left + ((currentValue - avg) / (maxValue - avg) * 50 || 0);
        } else {
            left = left + ((currentValue - avg) / (avg - minValue) * 50 || 0);
        }
        if (key === 'bath' || key === 'bed') {
            avg = parseFloat(avg).toFixed(2);
        } else {
            avg = Math.round(avg);
        }
        var formatMaxValue, formatMinValue, formatAvgValue, formatCurrentValue;
        if (key === 'price' || key === 'hoafee') {
            formatMaxValue = '$' + format.friendlyPrice(maxValue);
            formatMinValue = '$' + format.friendlyPrice(minValue);
            formatAvgValue = '$' + format.friendlyPrice(avg);
            formatCurrentValue = '$' + format.friendlyPrice(currentValue);
        } else if (key === 'pricePerArea') {
            formatMaxValue = '$' + format.formatNumberByComma(maxValue) + '/Sqft';
            formatMinValue = '$' + format.formatNumberByComma(minValue) + '/Sqft';
            formatAvgValue = '$' + format.formatNumberByComma(avg) + '/Sqft';
            formatCurrentValue = '$' + format.formatNumberByComma(currentValue) + '/Sqft';
        } else if(key === 'area') {
            formatMaxValue = format.area(maxValue, false) + ' Sqft';
            formatMinValue = format.area(minValue, false) + ' Sqft';
            formatAvgValue = format.area(avg, false) + ' Sqft';
            formatCurrentValue = format.area(currentValue, false) + ' Sqft';
        }
         else if (key === 'lotSize') {
            formatMaxValue =  format.formatFriendlyLotSize(maxValue,dpp.lotSizeUnit)
            formatMinValue =  format.formatFriendlyLotSize(minValue,dpp.lotSizeUnit)
            formatAvgValue =  format.formatFriendlyLotSize(avg,dpp.lotSizeUnit)
            formatCurrentValue =  format.formatFriendlyLotSize(currentValue,dpp.lotSizeUnit)
        } else {
            formatMaxValue = maxValue;
            formatMinValue = minValue;
            formatAvgValue = avg;
            formatCurrentValue = currentValue;
        }
        var className = '';
        if(key === 'price' || key === 'pricePerArea' || key === 'hoafee') {
            className = left < 50 ? 'green' : 'red'
        }
        if(key === 'bed' || key === 'bath' || key === 'area' || key === 'lotSize' || key === 'yearBuilt') {
            className = left > 50 ? 'green' : 'red'
        }
        if (left > 80) {
            className += ' right';
        } else if (left < 20) {
            className += ' left';
        }
        return {
            title: field.title,
            formatMaxValue: formatMaxValue,
            formatMinValue: formatMinValue,
            formatAvgValue: formatAvgValue,
            formatCurrentValue: formatCurrentValue,
            currentValue: currentValue,
            className: className,
            formatPercentage: (left > 50 ? '+' : '') + percentage + ((key === 'yearBuilt')?'':'%'),
            left: left + '%'
        };
    });
    return tmp;
}

function getDppSectionPreferences(section){
    let dppInfo = !isSSR && $.getStorage('dppPreferences')
    return dppInfo && dppInfo[section]
}

function setDppSectionPreferences(data) {
    let localData = !isSSR && $.getStorage('dppPreferences')
    if(!data || !data.section || !data.details) return

    let section = data.section
    let updatedData = localData || {}
    if( localData && localData[section]) {
        updatedData = localData
    }
    else {
        updatedData[section] = {}
    }
    for(let detail of data.details) {
        if(detail.key) {
            updatedData[section][detail.key] = detail.value
        }
    }
    !isSSR && $.setStorage('dppPreferences', updatedData);
}

function isLandOrMobile(dpp) {
    return ['LAND', 'MOBILE'].includes(dpp.propertyType)
}

function isMinorProperty(dpp) {
    return dpp && (dpp.price < 100000 || this.isLandOrMobile(dpp))
}

export default {
    populatePRDescription,
    populatePRDescriptionNew,
    populatePRMetaDescription,
    compareClosestProperties,
    compareClosestPropertiesLight,
    getFields,
    getDppSectionPreferences,
    setDppSectionPreferences,
    isMinorProperty,
    isLandOrMobile
};
