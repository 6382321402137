<template>
  <div class="mvt-collapsiblelinks grid xs-grid-cols-1">
    <component :is="titleTag" :class="titleClass" v-if="title">{{ title }}</component>
    <ul :class="`mvt-collapsiblelinks__grid grid xs-grid-cols-2 sm-grid-cols-3 md-grid-cols-${numCols}`">
      <li v-for="(item, index) in links" class="mvt-collapsiblelinks__grid-item singleline" :style="{display: (index > limit-1 && !isExpanded)?'none':'block'}">
        <div v-if="isLinkList(item)" :key="`list-${index}`">
          <template v-if="item.multipart">
            <template v-for="(subitem, subIndex) in item.parts" :key="`list-multipart-${subIndex}`">
              <a v-html="subitem.text" class="link" :href="subitem.url"></a>{{ " " }}
            </template>
          </template>
          <a v-else v-for="(subitem, subIndex) in item" v-html="subitem.text" class="link" :style="{display: 'block'}" :href="subitem.url" :key="`list-single-${subIndex}`"></a>
        </div>
        <a v-else class="link" v-html="item.text" :href="item.url" :key="`single-${index}`"></a>
      </li>
    </ul>
    <a v-if="!forceExpanded" href="#" role="button" :aria-label="isExpanded ? 'View Less' : 'View All'" class="more link" :class="{'hide' : links.length<=limit}" @click.prevent="toggle()">{{isExpanded ? 'View Less' : 'View All'}}</a>
  </div>
</template>

<script src="./mvt-collapsiblelinks.js">
</script>

<style lang="scss">
@import "./mvt-collapsiblelinks.scss";
</style>