<template>
    <div class="radio" :class="[theme, disabled]">
        <label v-for="item in data" :data-value="item.displayValue || item.value"  v-ga:clickable="item.value" :class="{'checked': _value === item.value}">
            <input type="radio" :value="item.value" :name="name" v-model="_value" :disabled="item.disabled" :checked="_value === item.value"/>
            <i></i>
            <span v-html="item.text"></span>
        </label>
    </div>
</template>
<script src="./mvt-radio.js"></script>
