import { mapGetters } from 'vuex';
import mvtNearby from '@/common/components/mvtnearby/mvt-nearby.vue';
import mvtLazyload from '@/common/components/mvtlazyload/mvt-lazyload.vue';

export default {
    name: 'homeSavedSearch',
    components: {
        mvtNearby,
        mvtLazyload,
    },
    data: function () {
        return {
            showSavedSearch : true
        }
    },
    computed: {
        ...mapGetters('glb', ['glb','getSavedSearches']),
        ...mapGetters('home', ['home']),
        savedSearchInfo(){
            let savedSearch = this.getSavedSearches && this.getSavedSearches[0] || null;
            return savedSearch ? {input : savedSearch.input && savedSearch.input.split('-').join(" "), url : savedSearch.url, searchType : savedSearch.searchType.toLowerCase() } : null;
        }
    },
    methods: {
        completed(data) {
            if (!data || !data.length) {
                this.showSavedSearch = false;
            }
            this.$refs.lazyload.ready();
        },
    },
};
