<template>
    <section id="opens">
        <div class="grid fixed inline gap-3 grid-sm-fix corner outline bg-default">
            <div>
                <img class="panel-img object-contain" alt="find home hotlead" :src="glb.cdnUrl+'images/desktop/dpp/home-finder.png'" loading="lazy"/>
            </div>
            <mvtHotleadForm v-if="hotleadOpt" class="middle" :options="hotleadOpt" />
        </div>
    </section>
</template>

<script src="./home-hotlead.js"></script>

