import { mapGetters, mapMutations, mapActions } from 'vuex';
import get from 'lodash/get';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import shuffle from 'lodash/shuffle';
import { appShare } from '@/common/utilities/appShare.js';
import mvtPlayer from '@/common/components/mvtplayer/mvt-player1.vue';
import mvtHotleadForm from '@/common/components/mvthotleadform/mvt-hotleadform.vue';
import { getKeyInsights } from '@/dpp/components/dppkeyinsights/dpp-keyinsights.js';
import propertyFuncs from '@/common/utilities/propertyFuncs';
import { formatPrice, formatFriendlyPrice, formatNumber } from '../../../common/filters/index.js';

const LAST_FOCUSED_LS_KEY = 'reels-view:last-focused-id';
const SHARE_CAMPAIGN = 'cw-video-share';
// pixels a swipe must cover to be considered large enough to close reels view from the top or bottom item;
const SWIPE_CLOSE_PX = 75;
// milliseconds to debounce the scroll handler by. Set this too small and it gets invoked during the middle of scrolls,
// too large and it takes too long after the scroll to fetch details
const SCROLL_DEBOUNCE_MS = 100;
// milliseconds duration of the closing animation. You may want to update the .reelsViewContainer transition timing if you update this value.
const CLOSING_ANIM_MS = 300;
// milliseconds from beginning of slideshow to wait before fading in the insights
const PROPERTY_INSIGHTS_ANIM_MS = 4700;
// milliseconds elapsed between taps to be considered a double tap
const DOUBLE_TAP_MS = 500;
// milliseconds after the last photo is shown to wait to display the end screen
const END_SCREEN_BUFFER_MS = 0;
// number of listings ahead of the currently focused one to prefetch details for
const PREFETCH_NUM = 3;
// number of key insights cards to show
const INSIGHTS_NUM = 1;
// minimum number of photos a listing must have to be included in reels view
const MIN_PHOTO_NUM = 8;

export async function importMuxPlayer() {
    return Promise.all([import(/* webpackChunkName: "mux-player-theme-minimal" */ '@mux/mux-player/themes/classic'), import(/* webpackChunkName: "mux-player" */ '@mux/mux-player')]);
}

export const processPhotos = (categorizedPhotos) => {
    if (!categorizedPhotos) {
        return [];
    }
    let photos = [];
    if (categorizedPhotos.length > 1) {
        // if there are multiple categories, append the a predetermined number of photos from each for good variety
        [
            { type: 'Exterior', count: 2 },
            { type: 'Living', count: 1 },
            { type: 'Bedroom', count: 2 },
            { type: 'Bath', count: 1 },
            { type: 'Kitchen', count: 2 },
        ].forEach(({ type, count }) => {
            const category = find(categorizedPhotos, (cat) => cat.tag === type);
            if (category && category.photos && category.photos.length > 0) {
                photos = photos.concat(category.photos.slice(0, count));
            }
        });
    } else {
        categorizedPhotos.forEach((c) => {
            if (c.photos) {
                photos = photos.concat(c.photos);
            }
        });
    }
    if (photos.length >= 80) {
        photos = photos.filter((_p, i) => i % 10 === 0);
    } else if (photos.length >= 40) {
        photos = photos.filter((_p, i) => i % 5 === 0);
    } else if (photos.length > 8) {
        photos = photos.slice(0, 8);
    }
    return photos;
};

export default {
    name: 'reelsView',
    inject: ['$eventBus'],
    components: {
        mvtPlayer,
        mvtHotleadForm,
    },
    props: {
        geo: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        data: {
            type: Array,
            required: false,
            default: () => [],
        },
        enabled: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            listings: [],
            visibleListings: [],
            listingDetails: {},
            nearbyProperties: {},
            closeAnimationTimeout: null,
            videoPlayerEventListenerTimeout: null,
            closingPos: null,
            lastTouchStartTime: null,
            touchStartY: null,
            touchDeltaY: null,
            moveTimer: null,
            clickTimer: null,
            isEnd: false,
            showInsight: false,
            showVideoControls: false,
            videoPlayerReady: false,
            observer: null,
        };
    },
    watch: {
        getData(newVal) {
            console.log('update reels view result', this.geo.city);
            this.listings = this.getListings(newVal);
        },
        visibleListings() {
            this.fetchListingDetails();
        },
        focusedListing() {
            this.play();
            if (!this.hasVideo) {
                this.fetchNearby();
            }
            if (this.forcusedListingId) {
                $.setStorage(LAST_FOCUSED_LS_KEY, this.forcusedListingId);
            }
        },
        enabled(newVal, oldVal) {
            if(newVal !== oldVal) {
                if(newVal) {
                    this.open();
                } else {
                    this.hide();
                }
            }
        },
    },
    computed: {
        ...mapGetters('msp', ['msp']),
        ...mapGetters('glb', ['glb', 'getSplit', 'favoriteListings']),
        hasVideo() {
            return this.focusedListing && this.focusedListing.videoURL;
        },
        geoTitle() {
            let geo = this.geo;
            if (!geo) return null;
            if (!geo.address && geo.zipcode) {
                return `${geo.zipcode}, ${geo.state}`;
            } else if (!geo.address && geo.neighborhood) {
                return `${geo.neighborhood}, ${geo.city}`;
            } else if (geo.city) {
                return `${geo.city}, ${geo.state}`;
            }
        },
        getData() {
            return this.data;
        },
        steps() {
            return [{ time: 2700 }, { time: 1600 }, { time: 2000 }, { time: 1600 }, { time: 1600 }, { time: 1600 }, { time: 2000 }, { time: 1600 }];
        },
        focusedListing() {
            if (!(this.visibleListings && this.visibleListings.length > 0)) {
                return null;
            }
            const id = this.visibleListings[0];
            const listing = get(
                this.listings.filter((listing) => listing.propertyId === id),
                0,
                null
            );
            if (listing) {
                listing.index = findIndex(this.listings, (l) => l.propertyId === listing.propertyId);
                listing.isFavorite = this.favoriteListings.includes(listing.propertyId);
            }
            return listing;
        },
        forcusedListingId() {
            if (this.focusedListing) {
                return this.focusedListing.propertyId;
            }
            return '';
        },
        hotleadOptions() {
            // hotlead form at end of property list
            let zipcode = '';
            let property = null;
            if (this.data && this.data.length) {
                property = this.data[0];
                zipcode = this.data[0].geo.zipcode;
            } else {
                zipcode = this.geo.zipcode;
            }
            return {
                hotleadTitle: this.molang('search.common.notRelevantResult'),
                hotleadType: 'VideoEndOfListings_Mobile',
                buttonText: this.molang('search.common.usQuestion'),
                zipcode: zipcode,
                comment: this.molang('search.common.hotleadcomments') + (property ? property.geo.city : ''),
                propertyData: property,
                showDisclaimer: false,
                showComment: true,
                hideProfile: true,
                hideIncentive: true,
            };
        },
        keyInsights() {
            const nearbyHome = this.nearbyProperties[this.forcusedListingId];
            const fields = propertyFuncs.getFields(this.focusedListing);
            const compareData = propertyFuncs.compareClosestPropertiesLight(this.focusedListing, nearbyHome, fields);
            const insights = getKeyInsights(this.focusedListing, compareData);
            return shuffle(insights).slice(0, INSIGHTS_NUM);
        },
        hasList() {
            return this.listings && this.listings.length > 0;
        },
    },
    methods: {
        ...mapActions('glb', ['updateFavorite', 'getFavoriteListings']),
        ...mapMutations('glb', ['updateMuted']),
        ...mapMutations('msp', ['updateViewStatus']),
        formatPrice,
        formatFriendlyPrice,
        formatNumber,
        getListings(data) {
            let arr = [];
            arr = data.filter((l) => l.videoURL || l.photoCount >= MIN_PHOTO_NUM);
            return arr.map((l) => ({
                ...l,
                isFavorite: this.favoriteListings.includes(l.propertyId),
                showInsight: false,
                isEnd: false,
                muted: true,
                videoPlayBackId: l.videoURL ? this.getPlaybackId(l.videoURL) : '',
            }));
        },
        onEnd(listing) {
            listing.isEnd = true;
            listing.showInsight = false;
        },
        onUpdate(i, listing) {
            if (i > 1 && !listing.showInsight) {
                listing.showInsight = true;
            }
        },
        toggleMute(listing, muted) {
            if (muted === undefined) {
                muted = !listing.muted;
            }
            this.updateMuted(muted);
            listing.muted = muted;
            document.querySelectorAll('mux-player').forEach((videoPlayer) => {
                let hasChange = false;
                if (this.glb.muted) {
                    if (videoPlayer && !videoPlayer.muted) {
                        hasChange = true;
                        videoPlayer.muted = true;
                    }
                } else {
                    if (videoPlayer.attributes['data-id'].value === listing.propertyId) {
                        if (videoPlayer.muted) {
                            hasChange = true;
                            videoPlayer.muted = false;
                        }
                    } else {
                        if (!videoPlayer.muted) {
                            hasChange = true;
                            videoPlayer.muted = true;
                        }
                    }
                }
                if (hasChange) {
                    let { listing } = this.getListingById(videoPlayer.getAttribute('data-id'));
                    if (listing && listing.muted !== videoPlayer.muted) {
                        listing.muted = videoPlayer.muted;
                    }
                }
            });
        },
        play(reset) {
            if (this.hasVideo) {
                this.playVideo(reset);
            } else {
                this.playSlideshow(reset);
            }
        },
        pause() {
            if (this.hasVideo) {
                this.pauseVideo();
            } else {
                this.pauseSlideshow();
            }
        },
        rewatch(listing) {
            listing.isEnd = false;
            listing.showInsight = false;
            this.play(true);
        },
        playVideo() {
            if (this.videoPlayerReady) {
                const videoPlayers = document.querySelectorAll('mux-player');
                videoPlayers.forEach((videoPlayer) => {
                    if (videoPlayer.getAttribute('data-id') === this.forcusedListingId) {
                        if (videoPlayer && videoPlayer.paused) {
                            if (this.glb.muted !== videoPlayer.muted) {
                                this.toggleMute(this.focusedListing, this.glb.muted);
                            }
                            videoPlayer.play();
                        }
                    } else if (videoPlayer && !videoPlayer.paused) {
                        videoPlayer.pause();
                    }
                });
            }
        },
        pauseVideo() {
            if (this.videoPlayerReady) {
                const videoPlayers = document.querySelectorAll('mux-player');
                videoPlayers.forEach((videoPlayer) => {
                    if (videoPlayer && !videoPlayer.paused) {
                        videoPlayer.pause();
                    }
                });
            }
        },
        playSlideshow(reset) {
            this.pauseVideo();
            if (this.$refs.mvtPlayer && this.$refs.mvtPlayer.length > 0) {
                this.$refs.mvtPlayer.forEach((r) => {
                    if (r.$el.getAttribute('data-id') === this.forcusedListingId) {
                        if (reset) {
                            r.replay();
                        } else if (!this.focusedListing.isEnd) {
                            r.play();
                        }
                    } else {
                        r.pause();
                    }
                });
            }
        },
        pauseSlideshow() {
            if (this.$refs.mvtPlayer && this.$refs.mvtPlayer.length > 0) {
                this.$refs.mvtPlayer.forEach((r) => {
                    r.pause();
                });
            }
        },
        getPlaybackId(url) {
            if (!url || url.indexOf('.m3u8') === -1) {
                return '';
            }
            const urlParts = url.split('/');
            const idWithExtension = urlParts[urlParts.length - 1];
            const idWithoutExtension = idWithExtension.replace('.m3u8', '');
            return idWithoutExtension;
        },

        onTouchStart(event) {
            if (!this.hasList && event.touches && event.touches.length > 0) {
                this.touchStartY = event.touches[0].clientY;
            }
        },
        onTouchMove(event) {
            if (!this.$el.classList.contains('scrolling')) {
                this.$el.classList.add('scrolling');
            }

            if (this.moveTimer) {
                clearTimeout(this.moveTimer);
            }

            this.moveTimer = setTimeout(() => {
                this.$el.classList.remove('scrolling');
            }, 200);

            if (!this.hasList && event.touches && event.touches.length > 0 && this.touchStartY) {
                let touchDeltaY = event.touches[0].clientY - this.touchStartY;
                this.touchDeltaY = touchDeltaY;

                //swipe move cannot be greater than 30px
                if (touchDeltaY > 30) {
                    touchDeltaY = 30;
                } else if (touchDeltaY < 30 * -1) {
                    touchDeltaY = 30 * -1;
                }
                this.$el.style.transform = 'translate3d(0, ' + touchDeltaY + 'px, 0)';
            }
        },
        onTouchEnd() {
            this.$el.classList.remove('scrolling');
            if (!this.hasList) {
                this.$el.classList.add('moving');
                if (this.touchDeltaY < SWIPE_CLOSE_PX * -1) {
                    this.$el.style.transform = `translate3d(0, -100%, 0)`;
                } else {
                    this.$el.style.transform = `translate3d(0, 0, 0)`;
                }
                setTimeout(() => {
                    this.$el.classList.remove('moving');
                    if (this.touchDeltaY < SWIPE_CLOSE_PX * -1) {
                        this.close();
                    }
                    this.touchDeltaY = 0;
                }, CLOSING_ANIM_MS);
            }
        },
        open() {
            this.$emit('show');
            $.router.add(this.$options.name);
            this.$el.style.transform = `translate3d(0, 0, 0)`;
            setTimeout(() => {
                this.$el.classList.remove('moving');
            }, CLOSING_ANIM_MS);
            $.openOverlayer('reels-view-active');
            $.setStorage('autoOpenReelsView', 1);

            this.install();
            let lastFocusedId = $.getStorage(LAST_FOCUSED_LS_KEY);
            if (!lastFocusedId && this.listings && this.listings.length > 0) {
                lastFocusedId = this.listings[0].propertyId;
            }
            lastFocusedId && this.scrollToListing(lastFocusedId);
            this.play();
        },
        hide() {
            this.$emit('hide');
            this.pause();
            $.closeOverlayer('reels-view-active');
            $.setStorage('autoOpenReelsView', 0);
        },
        close() {
            this.hide();
            $.router.back(this.$options.name);
        },
        onClickOverlayer(event, listing) {
            let isDbClick = false;
            if (this.lastTouchStartTime) {
                const timeStamp = event && event.timeStamp;
                const timeElapsed = timeStamp - this.lastTouchStartTime;
                if (timeElapsed < DOUBLE_TAP_MS) {
                    isDbClick = true;
                } else {
                    this.lastTouchStartTime = timeStamp;
                }
            } else {
                this.lastTouchStartTime = event && event.timeStamp;
            }

            if (this.clickTimer) {
                clearTimeout(this.clickTimer);
            }
            if (!isDbClick) {
                this.clickTimer = setTimeout(() => {
                    this.showVideoControls = !this.showVideoControls;
                }, DOUBLE_TAP_MS + 1);
            } else {
                this.favorite(listing);
                event.preventDefault();
            }
        },
        favorite(listing) {
            if (!listing) {
                return;
            }
            const options = {
                propertyData: {
                    formatMlsInfo: listing.formatMlsInfo,
                    geo: listing.geo,
                    mlsDescription: listing.mlsDescription,
                    mlsLogoSrc: listing.mlsLogoSrc,
                    photoCount: listing.photoCount,
                    tnImgPath: listing.tnImgPath,
                },
                propertyId: listing.propertyId,
                listingId: listing.listingId,
                isPrOnly: listing.isPrOnly,
                isFavorite: !(this.glb.favoriteListings.indexOf(listing.propertyId) >= 0),
                trigger: this.$options.name,
                lite: true,
            };

            this.updateFavorite(options).then((res) => {
                if (res.status && res.status.code === 0 && options.isFavorite) {
                    this.$eventBus.$emit('dialog-center-tip', `<span class="text-bold">Success!</span> <span class="text-medium">Home saved.</span>`);
                }
            });
        },
        openDpp(listing) {
            if (!listing || !listing.dppUrl) {
                return;
            }
            if (this.hasVideo) {
                this.$eventBus && this.$eventBus.$emit('ga', { ec: this.$options.name, en: 'click_custom', el: 'video_img_thumb' });
            } else {
                this.$eventBus && this.$eventBus.$emit('ga', { ec: this.$options.name, en: 'click_custom', el: 'photo_img_thumb' });
            }
            window.open(listing.dppUrl, '_blank');
        },
        share(listing) {
            if (!listing) {
                return;
            }
            if (this.hasVideo) {
                this.$eventBus && this.$eventBus.$emit('ga', { ec: this.$options.name, en: 'click_custom', el: 'video_share' });
            } else {
                this.$eventBus && this.$eventBus.$emit('ga', { ec: this.$options.name, en: 'click_custom', el: 'photo_share' });
            }
            // logic mostly copied from sendEmail fn from property-button.js
            const dppURL = this.glb.appUrl + listing.path;
            const propertyId = this.forcusedListingId;
            const smsChar = this.glb.OS === 'iOS' ? '%26' : '%3F';
            let sms = `utm_campaign%3D${SHARE_CAMPAIGN}${smsChar}utm_medium%3Dsms${smsChar}utm_source%3Dmsp`;
            if (this.glb.user.id) {
                sms += `${smsChar}utm_term%3D${this.glb.user.id}`;
            } else {
                let anonymousId = $.getCookie('ajs_anonymous_id');
                if (anonymousId) {
                    sms += `${smsChar}utm_term%3D${anonymousId.replace(/"/g, '')}`;
                }
            }
            if (this.glb.user.role) {
                if (['COBROKER_AGENT', 'AGENT'].includes(this.glb.user.role)) {
                    sms += `${smsChar}utm_content%3D${this.glb.user.role}`;
                }
            }
            if (this.glb.influenceAgentId) {
                sms += `${smsChar}agentid%3D${this.glb.influenceAgentId}`;
            }

            if (this.glb.isMobile && navigator.share) {
                sms = sms.replace(/%3F/g, '%26');
                sms = `${dppURL}%3F${sms}`;
                navigator.share({
                    title: this.molang('dpp.mvtEmail.shareHome'),
                    text: this.molang('dpp.mvtEmail.checkoutHome'),
                    url: unescape(sms),
                });
            } else {
                let emailOpt = {
                    propertyId,
                    listingId: listing.listingId,
                    listingUrl: this.glb.appUrl + listing.path,
                    staticMapUrl: listing.mapUrls && listing.mapUrls.length > 2 ? listing.mapUrls[2].src : '',
                    propertyData: listing,
                    showComment: false,
                    comment: `I am interested in ${listing.geo.formatAddress}.`,
                    sms: `${this.geo.formatAddress} ${dppURL}%3F${sms}`,
                    dialogTitle: this.molang('dpp.mvtEmail.shareHome'),
                    baseUrl: this.glb.appUrl + listing.path,
                };
                if (this.glb.isMobile && appShare.isWebViewMode()) {
                    // through native share
                    appShare.sentNativeEventToAPP(this.glb.OS, 'shareDPP', { dppURL, propertyId });
                } else {
                    // through email
                    this.$eventBus.$emit('dialog-center', { name: 'mvtSendEmail', trigger: this.$options.name, opt: emailOpt });
                }
            }
        },
        openHotlead(listing) {
            if (this.hasVideo) {
                this.$eventBus && this.$eventBus.$emit('ga', { ec: this.$options.name, en: 'click_custom', el: 'video_hotlead' });
            } else {
                this.$eventBus && this.$eventBus.$emit('ga', { ec: this.$options.name, en: 'click_custom', el: 'photo_hotlead' });
            }
            // hotlead form at end of each property
            this.$eventBus.$emit('dialog-center', {
                name: 'mvtHotleadForm',
                trigger: this.$options.name,
                opt: {
                    hotleadTitle: 'Request a Tour',
                    propertyId: listing.propertyId,
                    propertyData: listing,
                    hotleadSub: `You can request a call, a home tour, or real estate help`,
                    hotleadType: 'VideoBumper_Mobile',
                    buttonText: 'Request a Tour',
                },
            });
        },
        async fetchListingDetails() {
            // fetch details for the currently visible listings plus PREFETCH_NUM more that should be visible when the user continues scrolling
            const indexOfLastVisibleListing = findIndex(this.listings, (l) => l.propertyId === this.visibleListings[this.visibleListings.length - 1]);
            const listingsToFetch = [...this.visibleListings];
            for (let i = 0; i < PREFETCH_NUM; i++) {
                const currentIndex = indexOfLastVisibleListing + i;
                if (this.listings[currentIndex + 1]) {
                    listingsToFetch.push(this.listings[currentIndex + 1].propertyId);
                }
            }
            listingsToFetch.forEach(async (id) => {
                const matchingListing = get(
                    this.listings.filter((listing) => listing.propertyId === id),
                    0,
                    null
                );
                // return if we already fetched the details or can't find a listing with the given id
                if (this.listingDetails[id] || !matchingListing) {
                    return;
                }
                if (matchingListing.videoURL) {
                    return;
                }
                try {
                    const queryString = new URLSearchParams({
                        url: matchingListing.path,
                    });
                    const infoRawResponse = await fetch(`/api/v/property/info/?${queryString}`);
                    const infoResponse = await infoRawResponse.json();
                    const listing = get(infoResponse, 'data', []);
                    if (listing) {
                        const photos = processPhotos(listing.categorizedPhotos);
                        this.listingDetails[id] = { photos, info: listing };
                    }
                } catch (err) {
                    console.error(`An error occurred fetching photos for property: ${id}`, err);
                }
            });
        },
        async fetchNearby() {
            if (!this.focusedListing || this.nearbyProperties[this.forcusedListingId]) {
                return;
            }
            try {
                const queryString = new URLSearchParams({
                    propertyId: this.forcusedListingId,
                    lat: this.focusedListing.geo.lat,
                    lng: this.focusedListing.geo.lng,
                    size: 30,
                });
                const rawResponse = await fetch(`/api/v/property/nearbysold/?${queryString}`);
                const response = await rawResponse.json();
                const nearby = get(response, 'data', []);
                this.nearbyProperties[this.forcusedListingId] = nearby;
            } catch (err) {
                console.error(`An error occurred fetching nearby properties for property: ${this.forcusedListingId}`, err);
            }
        },
        getPlayerData(id) {
            const rawPhotos = this.listingDetails[id] && this.listingDetails[id].photos;
            if (!rawPhotos) {
                return null;
            }
            return rawPhotos.map((p) => p.url);
        },
        getListingById(id) {
            let index = this.listings.findIndex((e) => e.propertyId === id);
            if (index >= 0) {
                return {
                    listing: this.listings[index],
                    index,
                };
            }
            return {
                listing: null,
                index: -1,
            };
        },
        scrollToListing(id) {
            const scrollableContainer = this.$refs.listingContainer;
            const scrollableItem = find(this.$refs.listings, ($el) => $el.getAttribute('data-id') === id);
            if (scrollableItem) {
                scrollableContainer.scrollTop = scrollableItem.offsetTop;
            } else {
                scrollableContainer.scrollTop = 0;
            }
        },
        clearTimeouts() {
            this.closeAnimationTimeout && clearTimeout(this.closeAnimationTimeout);
            this.videoPlayerEventListenerTimeout && clearTimeout(this.videoPlayerEventListenerTimeout);
        },
        getPlayedPercent(player) {
            if (!player) {
                return -1;
            }
            const current = player.currentTime;
            const duration = player.duration;
            const percent = Math.round((current / duration) * 100) || 0;
            return Math.ceil(percent / 5) * 5 || 0;
        },
        onVideoPlay(event) {
            let id = event.target && event.target.getAttribute('data-id');
            if (id) {
                let { listing, index } = this.getListingById(id);
                if (listing && listing.isEnd) {
                    listing.isEnd = false;
                }
                this.showVideoControls = false;
                let percentage = this.getPlayedPercent(event.currentTarget);
                if (percentage >= 0 && percentage <= 100) {
                    this.$eventBus && this.$eventBus.$emit('ga', { ec: this.$options.name, en: 'video_start', el: `${index}-${percentage}` });
                }
            }
        },
        onVideoPause(event) {
            let id = event.target && event.target.getAttribute('data-id');
            if (id) {
                let { index } = this.getListingById(id);
                let percentage = this.getPlayedPercent(event.currentTarget);
                this.$eventBus && this.$eventBus.$emit('ga', { ec: this.$options.name, en: 'video_pause', el: `${index}-${percentage}` });
            }
        },
        onVideoEnded(event) {
            let id = event.target && event.target.getAttribute('data-id');
            if (id) {
                let { listing, index } = this.getListingById(id);
                if (listing && !listing.isEnd) {
                    listing.isEnd = true;
                }
                this.showVideoControls = true;
                this.$eventBus && this.$eventBus.$emit('ga', { ec: this.$options.name, en: 'video_complete', el: `${index}-${this.getPlayedPercent(event.currentTarget)}` });
            }
        },
        addVideoPlayerEventListeners() {
            this.$refs.videoPlayer && this.$refs.videoPlayer.forEach((r) => r.addEventListener('play', this.onVideoPlay));
            this.$refs.videoPlayer && this.$refs.videoPlayer.forEach((r) => r.addEventListener('pause', this.onVideoPause));
            this.$refs.videoPlayer && this.$refs.videoPlayer.forEach((r) => r.addEventListener('ended', this.onVideoEnded));
        },
        async initializeVideoPlayer() {
            importMuxPlayer().then(() => {
                this.videoPlayerReady = true;
                requestAnimationFrame(() => {
                    this.addVideoPlayerEventListeners();
                    this.playVideo();
                });
            });
        },
        uninstall() {
            this.clearTimeouts();
            this.$el.querySelectorAll('.reels-view-list-item').forEach((el) => {
                this.observer.unobserve(el);
            });
            this.observer.disconnect();
            this.observer = null;
            this.$el.classList.remove('moving');
            this.$el.style.transform = `translate3d(0, 0, 0)`;
            this.$refs.videoPlayer && this.$refs.videoPlayer.forEach((r) => r.removeEventListener('play', this.onVideoPlay));
            this.$refs.videoPlayer && this.$refs.videoPlayer.forEach((r) => r.removeEventListener('pause', this.onVideoPause));
            this.$refs.videoPlayer && this.$refs.videoPlayer.forEach((r) => r.removeEventListener('ended', this.onVideoEnded));
        },
        install() {
            this.initializeVideoPlayer();
            if (window.IntersectionObserver) {
                if (!this.observer) {
                    const options = {
                        root: this.$refs.listingContainer,
                        rootMargin: '-50% 0px -50% 0px',
                        threshold: 0,
                    };
                    this.observer = new IntersectionObserver((entries) => {
                        let array = [];
                        entries.forEach((entry) => {
                            if (!entry.isIntersecting) {
                                return;
                            }
                            if (!entry.target.attributes['data-id']) {
                                this.visibleListings = [];
                                return;
                            }
                            array.push(entry.target.attributes['data-id'].value);
                            //also push push prev node and next node as buffer
                            if (entry.target.previousElementSibling && entry.target.previousElementSibling.attributes['data-id']) {
                                array.push(entry.target.previousElementSibling.attributes['data-id'].value);
                            }
                            if (entry.target.nextElementSibling && entry.target.nextElementSibling.attributes['data-id']) {
                                array.push(entry.target.nextElementSibling.attributes['data-id'].value);
                            }
                        });
                        if (array && array.length > 0) {
                            this.visibleListings = array;
                        }
                    }, options);
                }
                this.$el.querySelectorAll('.reels-view-list-item').forEach((el) => {
                    this.observer.observe(el);
                });
            }
        }
    },
    beforeMount() {
        this.listings = this.getListings(this.data);
    },
    mounted() {
        this.install();
        this.$refs.listingContainer && this.$refs.listingContainer.addEventListener('touchstart', this.onTouchStart);
        this.$refs.listingContainer && this.$refs.listingContainer.addEventListener('touchmove', this.onTouchMove);
        this.$refs.listingContainer && this.$refs.listingContainer.addEventListener('touchend', this.onTouchEnd);
        $(document).on('dom.key.esc.' + this.vnumber, this.close);
        $(document).on('dom.popstate.' + this.vnumber, (e, key) => {
            if (!key || key !== this.$options.name) {
                this.hide();
            }
        });

        if (this.glb.user.id) {
            this.getFavoriteListings();
        }
        if (this.glb.isMobile && appShare.isWebViewMode() && this.focusedListing) {
            appShare.registerAppShareHandlers(this.forcusedListingId);
        }
        if ($.getStorage('autoOpenReelsView') === 1) {
            this.open();
        }
    },
    beforeUnmount() {
        this.uninstall();
        this.$refs.listingContainer && this.$refs.listingContainer.removeEventListener('touchstart', this.onTouchStart);
        this.$refs.listingContainer && this.$refs.listingContainer.removeEventListener('touchmove', this.onTouchMove);
        this.$refs.listingContainer && this.$refs.listingContainer.removeEventListener('touchend', this.onTouchEnd);
        $(document).off('dom.key.esc.' + this.vnumber);
        $(document).off('dom.popstate.' + this.vnumber);
    },
};
