import {mapGetters} from 'vuex';
import throttle from 'lodash/throttle';
import MvtShifterPresentational from '@/common/components/mvtshifter/mvt-shifter.presentational.vue'

function loadImg(img){
    var $img = $(img);
    var imgSrc = $img.attr('data-src');
    if(imgSrc){
        $.loadImg($img, imgSrc);
    }
    $img.removeAttr('data-src');
}

export default {
    name: 'mvtShifter',
    props: {
        center: {
            type: Boolean,
            default: true,
        },
        theme: {
            type: String,
            default: 'default',
        }
    },
    components: {
        MvtShifterPresentational
    },
    computed: {
        ...mapGetters('glb', ['glb']),
        showArrow() {
            return !this.glb.isMobile;
        },
    },
    data() {
        return {
            hasNext: false,
            hasPrev: false,
            observer: null,
            mo: null,
        };
    },
    methods: {
        refresh() {
            var that = this;
            this._updateWidth();
            var $scroller = $(this.$refs.presentationalScroller.$refs.scroller);
            $scroller.find('[data-src]').each(function (i, e) {
                that.setImg(e);
            });
        },
        setImg(img) {
            var that = this;
            var scroller = this.$refs.presentationalScroller.$refs.scroller;
            if (window.IntersectionObserver) {
                if (!this.observer) {
                    const options = {
                        root: scroller,
                        rootMargin: '0% 100% 0% 100%',
                        threshold: 0,
                    };
                    this.observer = new IntersectionObserver(function (entries) {
                        entries.forEach((entry) => {
                            if (!entry.isIntersecting) {
                                return;
                            }
                            loadImg(entry.target);
                            that.observer.unobserve(entry.target);
                        });
                    }, options);
                }
                this.observer.observe(img);
            } else {
                loadImg(img);
            }
        },
        _updateWidth() {
            this.checkArrow();
        },
        checkArrow() {
            var $scroller = $(this.$refs.presentationalScroller.$refs.scroller);
            if (!this.showArrow) {
                return;
            }
            var scrollLeft = $scroller.scrollLeft();
            var innerWidth = $scroller.innerWidth();
            var scrollWidth = $scroller.prop('scrollWidth');
            if (scrollLeft + innerWidth >= scrollWidth - 10) {
                this.hasNext = false;
            } else {
                this.hasNext = true;
            }
            if (scrollLeft <= 10) {
                this.hasPrev = false;
            } else {
                this.hasPrev = true;
            }
        },
        _inital() {
            var that = this;
            var $scroller = $(this.$refs.presentationalScroller.$refs.scroller);
            this.refresh();
            $(document).on('dom.resize.' + this.vnumber, this._updateWidth);
            const config = { attributes: false, childList: true, subtree: false };
            this.mo = new MutationObserver(throttle(this.checkArrow, 150));
            if(this.mo){
                this.mo.observe(this.$refs.presentationalScroller.$refs.scroller, config);
            }
            $scroller.on(
                'scroll',
                throttle(function () {
                    that.$emit('scroll');
                    that.checkArrow();
                }, 150)
            );
        },
        getOffset($el, next) {
            var offset = 0;
            var $scroller = $(this.$el);
            let screenWidth = $scroller.innerWidth();
            $el.each(function (index, item) {
                var $this = $(item);
                var left = $this.position().left;
                var width = $this.outerWidth(true);
                if (next) {
                    if (left + width >= screenWidth) {
                        offset = left;
                        return false;
                    }
                } else {
                    if (left + width > -1) {
                        offset = screenWidth - left - width;
                        return false;
                    }
                }
            });
            return offset;
        },
        next() {
            if (!this.hasNext) {
                return;
            }
            const $scroller = $(this.$refs.presentationalScroller.$refs.scroller);
            const offset = this.getOffset($scroller.children(), true);
            const left = $scroller.scrollLeft();
            $scroller.scrollLeft(left + offset);
        },
        prev() {
            if (!this.hasPrev) {
                return;
            }
            const $scroller = $(this.$refs.presentationalScroller.$refs.scroller);
            const offset = this.getOffset($scroller.children(), false);
            const left = $scroller.scrollLeft();
            $scroller.scrollLeft(left - offset);
        },
    },
    beforeUnmount() {
        this.mo && this.mo.disconnect();
        $(document).off('dom.resize.' + this.vnumber);
    },
    mounted() {
        var that = this;
        setTimeout(function () {
            that._inital();
        }, 1);
    },
};
