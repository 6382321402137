import { mapGetters, mapMutations } from 'vuex';
import mvtSearchbox from '@/common/components/mvtsearchbox/mvt-searchbox.vue';
import mvtInputGeo from '@/common/components/mvtinputgeo/mvt-inputgeo.vue';
import HomeWelcomePresentational from './home-welcome.presentational.vue';

export default {
    name: 'homeWelcome',
    inject: ['$eventBus'],
    components: {
        mvtSearchbox,
        mvtInputGeo,
        HomeWelcomePresentational,
    },
    computed: {
        ...mapGetters('glb', ['viewport', 'glb']),
        ...mapGetters('home', ['home']),
        homeMain() {
            return this.home.homeImgWebp;
        },
    },
    methods: {
        ...mapMutations('glb', ['updatePopLead']),
        popSellLead(data) {
            let opt = {
                hotleadType: this.glb.isMobile ? 'PopupHotleadInPricingTool_sellerMOBILE' : 'PopupHotleadInPricingTool_seller',
                hotleadTitle: 'Message an Agent About Selling',
                hotleadSub: 'Talk to a top local agent about selling your home today. No obligation.',
                buttonText: 'Message Agent',
                showComment: false,
                hideVeteran: true,
                noSecondaryLead: true,
            };
            let name = 'mvtSellLead';
            if (data && data.listings && data.listings.length) {
                var listing = data.listings[0];
                if (data.listings.length === 1) {
                    opt.propertyId = listing.propertyId;
                }
                opt.propertyData = listing;
            }
            this.$eventBus.$emit('dialog-center', {
                name: name,
                trigger: this.$options.name,
                opt: opt,
            });
        },
        showSellHotlead(geoInfo) {
            this.$eventBus.$emit('eventbus-search-address', {
                text: geoInfo.text,
                geo: geoInfo.geo,
                getClaimedHome: true,
                getFirstListing: this.popSellLead,
            });
        },
    },
    mounted() {
        if (this.glb.isPhone) {
            $(this.$el)
                .find('input')
                .on('focus', function () {
                    let scrollTop = $(window).scrollTop();
                    document.getElementById('searchTab').scrollIntoView();
                    $(this).one('blur', function () {
                        setTimeout(() => {
                            window.scrollTo(0, scrollTop);
                        }, 100);
                    });
                });
        }
    },
    beforeUnmount() {},
};
