<template>
    <div :class="{'card-agent':!simple,'card-agent-simple':simple}">
        <template v-if="simple">
            <img :src="agent.image" loading="lazy" alt="Agent Image" @error="defaultImg" />
            <div class="singleline text-capitalize">
                {{agent.firstname}} {{agent.lastname}}
            </div>
            <div>
                <div class="agent-title">
                    <img v-if="agent.isMovotoAgent" loading="lazy" style="height:16px;width:16px" :src="glb.cdnUrl + 'vimages/movoto-logo-m-cirlce-green.svg'" alt="Movoto Logo"> <span class="f7 text-gray">  {{agent.title}}</span>
                </div>
                <div v-if="agent.rating" class="agent-rating f8">
                    <span>{{agent.rating}}/5</span>
                    <template v-for="index in 5">
                        <i class="icon-star" v-if="agent.rating - index >= 0"></i>
                        <i class="icon-star-half" v-else-if="agent.rating - index > -1"></i>
                        <i class="icon-star-o" v-else></i>
                    </template>
                </div>
            </div>
        </template>
        <template v-else>
            <div  class="agent-card-info">
                <img :src="agent.image" alt="Agent Image" @error="defaultImg" />
                <div>
                    <div class="agent-title">
                        <img v-if="agent.isMovotoAgent" loading="lazy" style="height:16px;width:16px" :src="glb.cdnUrl + 'vimages/movoto-logo-m-cirlce-green.svg'" alt="Movoto Logo"> <span class="f7 text-gray"> {{agent.title}}</span>
                    </div>
                    <div class="f4 text-medium text-capitalize">
                        {{agent.firstname}}
                        <br/>
                        {{agent.lastname}}
                    </div>
                    <div>
                        <template class="agent-rating f7 text-brand" v-if="agent.rating">
                            <span>{{agent.rating}}/5</span>
                            <template v-for="index in 5">
                                <i class="icon-star" v-if="agent.rating - index >= 0"></i>
                                <i class="icon-star-half" v-else-if="agent.rating - index > -1"></i>
                                <i class="icon-star-o" v-else></i>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
            <button class="btn secondary small">{{molang('dpp.propertyAgents.askQuestion')}}</button>
        </template>
    </div>
</template>

<script src="./mvt-cardagent.js"></script>
