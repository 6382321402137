import { mapGetters } from 'vuex';

export default{
    name: 'mvtLazyload',
    inject: ['$eventBus'],
    props: {
        async:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            active: false,
            dataReady: false,
        };
    },
    computed: {
        ...mapGetters('glb', [ 'glb']),
        loaded(){
            return this.active && (!this.async || this.dataReady);
        }
    },
    methods:{
        load(){
            requestAnimationFrame(()=>{
                this.active = true;
            });
        },
        ready(){
            this.dataReady = true;
        }
    }
};
