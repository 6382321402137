<template>
    <section>
        <div class="grid fixed xs-grid-cols-1">
            <template v-if="home?.seoReducedListings">
                <div class="text-center">
                    <h2 class="f3 text-bold">
                        Price Reductions
                    </h2>
                </div>
                <mvtNearby cardFilter="priceCut" :data="home?.seoReducedListings" carousalType="nationwide_reduced"></mvtNearby>
            </template>
            <template v-else>
                <div class="text-center">
                    <h2 class="f3 text-bold">
                        {{molang('home.ReduceDesp')}} <a :href="glb.appUrl + home.geo.priceReducePath " class="link">{{home.geo.city}}</a>
                    </h2>
                </div>
                <mvtLazyload ref="lazyload" :async="true" data-role="propertycard">
                    <mvtNearby cardFilter="priceCut" type="city" filterPath="/single-family/reduced-30/" @completed="completed" carousalType="reduced"></mvtNearby>
                </mvtLazyload>
            </template>
        </div>
    </section>
</template>

<script src="./home-nearbyreduce.js"></script>
