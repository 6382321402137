<template>
         <div class="mvt-calendar toggle">
            <p class="f5 text-bold optional-label" v-if="optionalDate">Select a date <span class="text-medium">Optional</span></p>
            <mvtShifter class="date" type="2">
                <template v-for="(date,index) in dateList">
                    <div v-if="index!=0 || !disableToday" :key="index">
                        <button type="button" @click="changeActiveDateIndex(index)" class="btn-calendar" :class="{active: index===activeDateIndex}" >
                            <span>{{date.day}}</span>
                            <span class="f3">{{date.dateNum}}</span>
                            <span>{{date.month}}</span>
                        </button>
                    </div>
                </template>
            </mvtShifter>
            <div class="group-calendar tab" v-if="!testCalendarView">
                <button type="button" @click="setTypeIndex(0)" class="btn-calendar" :class="{active: activeTypeIndex===0}"><i :class="icon"></i> <span>{{molang('dpp.propertySchedule.inPerson')}}</span></button>
                <button type="button" @click="setTypeIndex(1)" class="btn-calendar" :class="{active: activeTypeIndex===1}"><i class="icon-video"></i> <span>{{molang('dpp.propertySchedule.liveVideo')}}</span></button>
            </div>
            <mvtShifter type="2" class="time" v-if="!testCalendarView" >
                <div v-for="(item,i) in timeList" :key='i'>
                    <button type="button" class="btn-calendar" @click="changeActiveTimeIndex(i)" :class="{active:activeTimeIndex == i}">{{item}}</button>
                </div>
            </mvtShifter>
        </div>

</template>
<script src="./mvt-calendar.js"></script>
