<template>
    <MvtShifterPresentational
        :center="center"
        :showArrow="showArrow"
        :hasPrev="hasPrev"
        :hasNext="hasNext"
        :onPrev="prev"
        :onNext="next"
        :theme="theme"
        ref="presentationalScroller"
    >
        <template v-for="(_, slot) in $slots" v-slot:[slot]="scope">
            <slot :name="slot" v-bind="scope || {}" />
        </template>
    </MvtShifterPresentational>
</template>
<script src="./mvt-shifter.js"></script>
