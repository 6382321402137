<template>
    <div class="input input-geo" :class="{'right': !hideButton}">
        <label v-if="isShowLabel">{{placeholder}}</label>
        <input type="text" ref="geo" :placeholder="placeholder" autocomplete="disabled"/>
        <button :class="btnclass"
            v-if="!hideButton"
            id="inputgeo-searchicon"
            @click="onClick" title="Search">
            <slot>
                <i class="icon-search" ></i>
            </slot>
        </button>
    </div>
</template>
<script src="./mvt-inputgeo.js"></script>
