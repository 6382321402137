export default {
    4001: {
        NewRentalRequestInfo: 'NewRentalRequestInfo_Zumper',
        'Rental-TYP-Request': 'Rental-TYP-Request_Zumper',
    },
    4002: {
        NewRentalRequestInfo: 'NewRentalRequestInfo_AL',
        'Rental-TYP-Request': 'Rental-TYP-Request_AL',
        validator: ['listingId', 'rentalListingId']
    }
}