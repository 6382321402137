import { mapGetters } from 'vuex';
import mvtHotleadForm from '@/common/components/mvthotleadform/mvt-hotleadform.vue';

export default {
    name: 'homehotlead',
    components: {
        mvtHotleadForm,
    },
    computed: {
        ...mapGetters('glb', ['glb', 'getSplit']),
    },
    data() {
        return {
            hotleadOpt: {
                hotleadTitle: this.molang('home.HotleadTitle'),
                hotleadSub: this.molang('home.hotleadSub'),
                hotleadType: 'ExpertConsultConsumerGlobalHomePageBottom',
                buttonText: this.molang('home.HotleadButtionCap'),
                showZipcode: true,
                hideProfile:  true
            },
        };
    },
};
