<template>
  <input type="tel"
        v-model="displayValue"
        v-restrict.number
        @blur="setActive(false)"
        @focus="setActive(true)"/>
</template>

<script>
import format from '@/common/utilities/format';
export default {
    name: 'mvtInputNumber',
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            required: true
        },
        prefix:{
            type: String,
            default: ''
        },
        suffix:{
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        }
    },
    data: function() {
        return {
            actived: false,
            val: this.modelValue || 0
        }
    },
    watch: {
        getValue() {
            this.val = this.getValue;
        }
    },
    methods: {
        setActive(actived) {
            this.actived = actived;
            if (this.actived) {
                this.$emit('actived');
            }
        },
    },
    computed: {
        getValue(){
            return this.modelValue;
        },
        displayValue: {
            get: function() {
                if (this.actived) {
                    return this.prefix + format.formatNumberByComma(this.val);
                } else {
                    return (this.prefix + format.formatNumberByComma(this.val) + this.suffix) || this.placeholder;
                }
            },
            set: function(modifiedValue) {
                let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ''))
                if (isNaN(newValue)) {
                    newValue = 0
                }
                this.val = newValue;
                this.$emit('update:modelValue', this.val);
            }
        }
    }
}
</script>
