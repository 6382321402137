import { computed, defineComponent, createCommentVNode, ref, onMounted, h } from 'vue';
export default defineComponent({
    name: 'mvtClientOnly',
    functional: true,
    props: {
        shouldShow: {
            type: Boolean,
            default: undefined,
        },
    },
    setup(props, { slots }) {
        const isMounted = ref(false);

        /* Even when wrapped, allow conditional rendering of component, where appropriate */
        const showDefaultComponent = computed(() => {
            if (props.shouldShow === undefined) {
                return isMounted.value;
            }
            return !!props.shouldShow && isMounted.value;
        });

        onMounted(() => {
            isMounted.value = true;
        });

        const defaultVnode = slots.default ? h(() => slots.default()) : createCommentVNode('Client-only rendering with empty default slot');
        const placeholderVNode = slots.placeholder ? h(() => slots.placeholder()) : createCommentVNode(`Client-only rendering component placeholder`);

        return () => (showDefaultComponent.value ? defaultVnode : placeholderVNode);
    },
});
