<template>
    <Schema :text="getSchema" />
    <Schema v-if="dppUseCase" :text="getRealEstateSchema" />
    <template v-else>
      <Schema v-if="getTourSchema" :text="getTourSchema" />
      <Schema v-if="getOpenHouseSchema" :text="getOpenHouseSchema" />
    </template>
</template>

<script>
import Schema from './json-schema.vue';

export default {
    name: 'PropertyCardSchema',
    props: {
      data: {
        type: Object,
        default: {}
      },
      dppUseCase : {
        type: Boolean,
        default: false
      }
    },
    components: { Schema },
    computed: {
        getSchema() {
          const formattedAddress = this.data.geo.formatAddress;
          const url = this.data.dppUrl;

          const schema = {
            "@context": "http://schema.org",
            "@type": "SingleFamilyResidence",
            "name": formattedAddress,
            url,
            "address": {
              "@type": "PostalAddress",
              "streetAddress": this.data.geo.address || '',
              "addressLocality": this.data.geo.city || '',
              "postalCode": this.data.geo.zipcode || '',
              "addressRegion": this.data.geo.state || '',
            },
          };

          if (this.data.photoCount) {
            schema.image = this.data.tnImgPath;
          }

          return JSON.stringify(schema);
        },

        getRealEstateSchema() {

          const schema = {
            "@context": "http://schema.org",
            "@type": "RealEstateListing",
            "name": this.data.geo.formatAddress,
            "description": this.data.description,
            "datePosted": new Date(this.data.listDate),
          };

          return JSON.stringify(schema);
        },

        hideSchema() {
          return ['OH','KY'].indexOf(this.data.geo.state) > -1
        },
        getTourSchema() {
          if (!this.data.threeDTourLink || this.data.threeDTourLink.length === 0 || this.hideSchema) {
            return false;
          }

          const now = new Date();
          const today = now.getFullYear() + '-' + String(now.getMonth() + 1).padStart(2, '0') + '-' + String(now.getDate()).padStart(2, '0');
          const formattedAddress = this.data.geo.formatAddress;
          const url = this.data.dppUrl;

          const schema = {
            "@context": "http://schema.org",
            "@type": "Event",
            "name": `3D/Virtual Tour - ${formattedAddress}`,
            url,
            "startDate": today,
            "endDate": today,
            "location": {
              "@type": "Place",
              "name": formattedAddress,
              "address": {
                "@type": "PostalAddress",
                "streetAddress": this.data.geo.address || '',
                "addressLocality": this.data.geo.city || '',
                "postalCode": this.data.geo.zipcode || '',
                "addressRegion": this.data.geo.state || '',
              },
            },
            "description": `3D/Virtual Tour at ${formattedAddress}`,
            "performer": {
                "@type": this.data.listingAgent ? 'Person': 'PerformingGroup',
                "name": this.data.listingAgent || this.data.officeListName,
            },
            "eventAttendanceMode": "https://schema.org/MixedEventAttendanceMode",
            "eventStatus": "https://schema.org/EventScheduled",
            "organizer": {
                "@type": this.data.listingAgent ? 'Person': 'PerformingGroup',
                "name": this.data.listingAgent || this.data.officeListName,
            },
          }

          if(this.data.photoCount) {
            schema.image = this.data.tnImgPath;
          }

          if (this.data.geo.lat && this.data.geo.lng) {
            schema.location.geo = {
              "@type": "GeoCoordinates",
              "latitude": this.data.geo.lat,
              "longitude": this.data.geo.lng
            };
          }

          if (this.data.price && this.data.isActive) {
            schema.offers = {
                "@type": "Offer",
                "url": this.data.dppUrl,
                "validFrom": today,
                "availability": "http://schema.org/InStock",
                "price": this.data.price,
                "priceCurrency": "USD"
            };
          }

          return JSON.stringify(schema);
        },
        getOpenHouseSchema() {
          if (!(this.data.openHouses && this.data.openHouses.length) || this.hideSchema) {
            return false;
          }

          const openHouse = this.data.openHouses[0];
          const formattedAddress = this.data.geo.formatAddress;
          const url = this.data.dppUrl;
          const price = this.data.price;

          const schema = {
            "@context": "http://schema.org",
            "@type": "Event",
            "name": this.data.appSeoJsonOpenHouse.name,
            url,
            "startDate": `${openHouse.date}T${openHouse.startTime}Z`,
            "endDate": `${openHouse.date}T${openHouse.endTime}Z`,
            "location": {
                "@type": "Place",
                "name": formattedAddress,
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": this.data.geo.address || '',
                    "addressLocality": this.data.geo.city || '',
                    "postalCode": this.data.geo.zipcode || '',
                    "addressRegion": this.data.geo.state || '',
                }
            },
            "description": `Open house at ${formattedAddress}`,
            "performer": {
                "@type": this.data.listingAgent ? 'Person': 'PerformingGroup',
                "name": this.data.listingAgent || this.data.officeListName,
            },
            "eventAttendanceMode": "https://schema.org/MixedEventAttendanceMode",
            "eventStatus": "https://schema.org/EventScheduled"
          }

          if(this.data.photoCount) {
            schema.image = this.data.tnImgPath;
          }

          if (this.data.geo.lat && this.data.geo.lng) {
            schema.location.geo = {
              "@type": "GeoCoordinates",
              "latitude": this.data.geo.lat,
              "longitude": this.data.geo.lng,
            }
          }

          if (price && this.data.isActive ) {
            schema.offers = {
                "@type": "Offer",
                "validFrom": `${openHouse.date}T${openHouse.startTime}Z`,
                "availability": "http://schema.org/InStock",
                "priceCurrency": "USD",
                url,
                price,
            };
          }

          return JSON.stringify(schema);
        }
    },
};
</script>
