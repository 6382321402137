import { mapGetters, mapActions } from 'vuex';
import mvtListProperty from '@/common/components/mvtlistproperty/mvt-listproperty.vue';
import sort from '@/common/utilities/sort';
import { getViewListItems, getSelectItem } from '../../../../widget/spec/ga4helper';
export default {
    name: 'mvtNearBy',
    inject: ['$eventBus'],
    provide() {
        return {
            carousalType: this.carousalType
        }
    },
    props: {
        carousalType: {
            type: String,
            default: "",
            required: false
        },
        data: {
            type: Array,
            default: [],
            required: false,
        },
        type: {
            type: String,
            required: false,
            validator(value) {
                const validTypes = [
                    'search',
                    'zipcode',
                    'city',
                    'neighborhood',
                    'county',
                    'viewed',
                    'location',
                    'attribute',
                ];
                return validTypes.includes(value)
            }
        },
        filterPath: {
            type: String,
            required: false,
        },
        cardFilter: {
            type: String,
            required: false,
        },
        filterId: {
            type: String,
            required: false,
        },
        attributeData: {},
        sortBy: {
            type: String,
            required: false,
            validator(value) {
                const validSortBy = [
                    'similar_desc',
                    'distance_asc',
                    'dom_asc',
                    'price_asc',
                    'price_desc',
                    'sqft_asc',
                    'sqft_desc',
                    'pricepersqft_asc',
                    'pricepersqft_desc',
                ];
                return validSortBy.includes(value)
            }
        },
        excludeRentals: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    components: {
        mvtListProperty,
    },
    data() {
        return {
            listings: [],
            attributeList: [],
        };
    },
    mounted() {
        if (this.type === 'filterPath') {
            this.getResultByFilterPath();
        }else if(['zipcode', 'city', 'neighborhood','county'].includes(this.type)) {
            this.getDefaultSearchResults();
        } else if (this.type === 'viewed') {
            this.getViewedResults();
        } else if (this.type === 'location') {
            this.getLocationResults();
        } else if (this.type === 'attribute') {
            this.getAttributeResults();
        } else {
            this.completed(this.data);
            console.log("Unable to fetch nearby for the following: ", { type: this.type })
        }
    },
    computed: {
        ...mapGetters('glb', [
            'glb',
            'getSplit',
            'viewedListings',
            'getRecentlyViewed',
            'getSurroundingListings',
            'getNearbyHomeFromAttribute',
        ]),
        getData() {
            if (this.data && this.data.length) {
                return this.data;
            }
            if(this.listings && this.listings.length){
                return this.listings;
            }
            if (this.type === 'viewed') {
                return this.getRecentlyViewed;
            } else if (this.type === 'location') {
                return this.getSurroundingListings;
            } else if (this.type === 'attribute') {
                return this.attributeList;
            }
            return [];
        },
        getSortedData() {
            return this.sortBy ? sort.sortProperty(this.sortBy, this.getData) : this.getData;
        },
    },
    methods: {
        ...mapActions('glb', [
            'searchByGeoPath',
            'fetchRecentView',
            'fetchSurroundingListings',
            'fetchNearbyHomeFromAttribute',
        ]),
        isInContainer(element) {
            //check if element is in current container
            const rect = element.getBoundingClientRect();

            return rect.left >= 0 && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
        },
        getViewedItems() {
            const propertyCards = this.$el.querySelectorAll('.mvt-cardproperty');
            const visibleCards = [];
            propertyCards.forEach((card) => {
                //let hasAttributeViewd = card.dataset('viewed');
                let hasAttributeViewd = card.dataset.viewed;
                if (!hasAttributeViewd && this.isInContainer(card)) {
                    card.dataset.viewed = true;
                    if (card.dataset.id) {
                        let item = this.getData.find((item) => item.propertyId === card.dataset.id);
                        if (item) {
                            visibleCards.push(item);
                        }
                    }
                }
            });
            return visibleCards;
        },
        sendSelectItem(propertyId) {
            if (this.carousalType) {
                let listId = `${this.glb.pageType.replace('v', '')}_carousal_${this.carousalType}`;
                let listName = `${this.glb.pageType.replace('v', '')} carousal ${this.carousalType.split('_').join(' ')}`.toUpperCase();
                let index = this.getData.findIndex((item) => item.propertyId === propertyId);
                if (index >= 0) {
                    this.$eventBus.$emit('ga4', { event: 'select_item' }, { eventData: getSelectItem(listId, listName, this.getData[index], index) });
                }
            }
        },
        sendViewItemList() {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(() => {
                const viewPortListings = this.getViewedItems();
                if (this.carousalType && viewPortListings && viewPortListings.length) {
                    let listId = `${this.glb.pageType.replace('v', '')}_carousal_${this.carousalType}`;
                    let listName = `${this.glb.pageType.replace('v', '')} carousal ${this.carousalType.split('_').join(' ')}`.toUpperCase();
                    this.$eventBus.$emit('ga4', { event: 'view_item_list' }, { eventData: getViewListItems(listId, listName, viewPortListings, this.getData) });
                }
            }, 1000);
        },
        scroll() {
            this.sendViewItemList();
        },
        completed(data) {
            this.$emit('completed', data);
            this.sendViewItemList();
        },
        getResultByFilterPath() {
            let searchPath = this.filterPath ? this.filterPath.replace(/^\/+/, '') : '';
            if (searchPath) {
                this.searchByGeoPath({
                    path: searchPath,
                }).then((data) => {
                    this.listings = data;
                    this.completed(this.getData);
                });
            } else {
                this.completed(null);
            }
        },
        getDefaultSearchResults() {
            // Find geoPath from type + "Path"
            const geoPath = this.glb.geo[`${this.type}Path`];
            // Remove intial slash if present
            let filter = this.filterPath ? this.filterPath.replace(/^\/+/, '') : '';
            // Append rentals if appropriate
            if (this.glb.rentals && !this.excludeRentals) {
                filter += 'rentals/';
            }
            if(geoPath) {
                this.searchByGeoPath({
                    path: geoPath + filter,
                    geo: this.glb.geo,
                }).then((data) => {
                    this.listings = data;
                    this.completed(this.getData);
                    this.$eventBus.$emit('eventbus-nearbyList', this.getData, this.getData.length, filter, this.type);
                });
            } else {
                this.completed(null);
                console.log("Unable to fetch nearby for the following, missing geoPath: ", { type: this.type, filter: filter})
            }
        },
        getViewedResults() {
            if (this.viewedListings && this.viewedListings.length > 0) {
                let ids = this.viewedListings;
                if (this.filterId) {
                    ids = ids.filter((id) => {
                        return id !== this.filterId;
                    });
                }
                this.fetchRecentView({ ids: ids }).then(() => {
                    this.completed(this.getData);
                    this.$eventBus.$emit('eventbus-nearbyList', this.getData, this.getData.length, this.type);
                });
            } else {
                this.completed(this.getData);
                this.$eventBus.$emit('eventbus-nearbyList', this.getData, this.getData.length, this.type);
            }
        },
        getLocationResults() {
            this.fetchSurroundingListings().then(() => {
                this.completed(this.getData);
                this.$eventBus.$emit('eventbus-nearbyList', this.getData, this.getData.length, this.type);
            });
        },
        getAttributeResults() {
            // fetch nearby homes having given attribute if present in state
            if (this.getNearbyHomeFromAttribute(this.attributeData.attributeTag)) {
                this.attributeList = this.getNearbyHomeFromAttribute(this.attributeData.attributeTag);
                this.completed(this.attributeList);
                this.$eventBus.$emit('eventbus-nearbyList', this.attributeList, this.attributeList && this.attributeList.length, this.attributeData.attributeTag);
                return;
            }
            // fetch nearby homes having given attribute form API
            this.fetchNearbyHomeFromAttribute(this.attributeData).then(() => {
                this.attributeList = this.getNearbyHomeFromAttribute(this.attributeData.attributeTag);
                this.completed(this.attributeList);
                this.$eventBus.$emit('eventbus-nearbyList', this.attributeList, this.attributeList && this.attributeList.length, this.attributeData.attributeTag);
            });
        },
    },
};
