import { mapGetters } from 'vuex';

export default {
    name: 'mvtInputDate',
    inject: ['$eventBus'],
    emits: ['update:modelValue'],
    props: {
        modelValue: {
        },
        disableWeekends: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            //time|date
            default: 'date'
        },
        min: {
            type: String,
            default: ''
        },
        max:{
            type: String,
            default: ''
        },
        maxTime:{
            type: String,
            default: '18:00'
        },
        minTime:{
            type: String,
            default: '9:00'
        }
    },
    data(){
        return {
            initaled: false
        };
    },
    computed: {
        ...mapGetters('glb', ['glb']),
        startDate(){
            if(this.min){
                if(this.type == 'time'){
                    return this.min + ' ' + this.minTime;
                }
                return this.min;
            }
            return '';
        },
        endDate(){
            if(this.max){
                if(this.type == 'time'){
                    return this.max + ' ' + this.maxTime;
                }
                return this.max;
            }
            return '';
        },
        icon(){
            return this.type == 'time' ? 'icon-clock' : 'icon-calendar';
        },
        _value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        },
        placeholder(){
            if(this.type == 'time'){
                return 'Select Time';
            } else if(this.type == 'date'){
                return 'Select Date';
            }
            return 'Select Date & Time';
        }
    },
    watch: {
        startDate(newVal, oldVal){
            if(this.initaled && newVal != oldVal){
                this.setStartDate(newVal);
            }
        },
        endDate(newVal, oldVal){
            if(this.initaled && newVal != oldVal){
                this.setEndDate(newVal);
            }
        }
    },
    methods: {
        setStartDate(val){
            var $input = $(this.$refs.input);
            if(this.isDate(val)){
                $input.datetimepicker && $input.datetimepicker('setStartDate', new Date(val) || null);
            }
        },
        setEndDate(val){
            var $input = $(this.$refs.input);
            if(this.isDate(val)){
                $input.datetimepicker && $input.datetimepicker('setEndDate', new Date(val) || null);
            }
        },
        open(){
            var $input = $(this.$refs.input);
            $input.datetimepicker && typeof $input.datetimepicker === 'function' && $input.datetimepicker('show');
        },
        isDate(date) {
            return (new Date(date) !== 'Invalid Date') && !isNaN(new Date(date));
        },
        async inital() {
            var that = this;
            var $input = $(this.$refs.input);
            var initialDate = this.isDate(this._value) ? new Date(this._value) : null;
            var opt = {
                container: $input.closest('.input'),
                todayBtn: false,
                autoclose: true,
                todayHighlight: true,
                viewSelect: 4,
                initialDate: initialDate,
                pickerPosition: undefined
            };
            if(this.disableWeekends){
                opt.daysOfWeekDisabled = [0, 6];
            }
            if(this.isDate(this.startDate)){
                opt.startDate = new Date(this.startDate);
            }
            if(this.isDate(this.endDate)){
                opt.endDate = new Date(this.endDate);
            }
            switch (this.type) {
                case 'date':
                    Object.assign(opt, {
                        format: 'mm/dd/yyyy',
                        startView: 2,
                        minView: 2,
                        maxView: 4
                    });
                    break;
                case 'time':

                    Object.assign(opt, {
                        showMeridian: true,
                        format: 'hh:ii',
                        startView: 1,
                        minView: 0,
                        maxView: 1,
                        keyboardNavigation: false
                    });
                    break;
                default:
                    Object.assign(opt, {
                        format: 'mm/dd/yyyy hh:ii'
                    });
                    break;
            }
            if (!$.fn.datetimepicker) {
                $.fn.datetimepicker = true;
                var registerdateTimePicker = await (await import(/* webpackChunkName: "datepick" */'./datepick')).default;
                registerdateTimePicker($);
                    $input.datetimepicker(opt);
                this.$eventBus.$emit('eventbus-inputdate-ready');
            } else if($.fn.datetimepicker === true) {
                this.$eventBus.$once('eventbus-inputdate-ready', () => {
                    $input.datetimepicker(opt);
                });
            } else{
                $input.datetimepicker(opt);
            }
            $input.on('change', function(){
                var val = $(this).val();
                if(that._value !== val){
                    that._value = val;
                }
            });
            this.open();
            this.initaled = true;
        }
    },
    mounted() {
        var $input = $(this.$refs.input);
        var that = this;
        var touch = 'ontouchstart' in window ? 'touchstart' : 'mousedown';
        $input.one(touch, function(){
            that.inital();
        });
    }
};
