<template>
    <div class="select" :class="{active: changed}" :changed="changed" :reset="options.defaultValue !== null" :label="label">
        <button v-if="options.defaultValue !== null" class="select-close" @click.stop="reset">
            <i class="icon-times"></i>
        </button>
        <div>
            <select v-model="_value" tabindex="0" :id="`selectLbl-${$.uid}`">
                <option v-for="item in data" :value="item.value">
                    {{ item.text }}
                </option>
            </select>
            <label v-html="selectedItemText" :for="`selectLbl-${$.uid}`"></label>
        </div>
    </div>
</template>

<script src="./mvt-select.js"></script>


