export default {
    name: 'lazycarouselcheck',
    inject: ['$eventBus'],
    data() {
        return {
            showList: { city: {
                            'new': true,
                            'open': true,
                            'price': true,
                            'condos': true,
                            'url': true
                        },
                        zipcode: {
                            'new': true,
                            'open': true,
                            'price': true,
                            'sold': true,
                        },
                        neighbourhood:{
                            'new': true,
                            'open': true
                        },
                        neighborhood:{
                            'new': true,
                            'open': true
                        },
                        county:{
                            'new': true,
                            'open': true
                        },
                        'location': true,
                        'agents': true,
                        'viewed': true,
                        'latlng': true
                    }
        };
    },
    mounted(){
      // return true;
      var that = this;
      this.$eventBus.$on('eventbus-nearbyList', function (listings, isShow, filter, type = '') {
          if(filter){
            (type !== '') ? that.showList[type][filter] = !!isShow : that.showList[filter] = !!isShow;
          }
      });

  },
  computed: {
    getShowAgentsLink(){
            return  this.showList['agents'];
    }
  },
  methods:{
      getShowList(filter, type){
        return  type ? this.showList[type][filter] : this.showList[filter];
      },
      setAttributeKeys(arr){
        if (arr && arr.length) {
            let obj = arr.reduce((curr, next) => {
                curr[next.attributeId] = true;
                return curr;
            }, {});
            Object.assign(this.showList, obj);
        }
      }
  }
};
