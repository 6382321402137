<template>
    <div id="container" data-type="vhome">
        <div class="page page-home"  v-ga:request="{pageType:'vhome', state: home}" v-ga:page="{pageType:'vhome', glb: glb, state: home}">
            <mvtHeader theme="transparent" :disableSearch="true" :geoInfo="home.geo"></mvtHeader>
            <homeWelcome></homeWelcome>
            <homeRadioBanner v-if="isGoMovotoCampaign"></homeRadioBanner>
            <mvtClientOnly v-if="getSplit('movoto-savedsearch-carousel-CW-1204') && glb.user && glb.user.id">
                <homeSavedSearch></homeSavedSearch>
            </mvtClientOnly>
            <homeNearbyNew></homeNearbyNew>
            <homeNearbyViewed v-if="!glb.rentals && glb.isUserBrowser && getShowList('viewed')"></homeNearbyViewed>
            <homeQRCode></homeQRCode>
            <homeNearbyOpen v-if="!glb.rentals && getShowList('open', 'city')"></homeNearbyOpen>
            <homeNearbyReduce v-if="!glb.rentals && getShowList('price', 'city')"></homeNearbyReduce>
            <homeHotlead v-if="!glb.rentals"></homeHotlead>
            <homeLinks></homeLinks>
            <homeNavigation></homeNavigation>
            <mvtFooter :geoInfo="home.geo" />
            <button type="button" @click="openContactUs" class="panel-fixed bottom-right circle large active main" aria-label="Contact Us"><i class="icon-mvt-chat"></i></button>
        </div>
        <mvtPopSignin />
        <mvtDialogCenter></mvtDialogCenter>
    </div>
</template>

<script src="./page-home.js"></script>

