<template>
    <article class="mvt-cardproperty"
        :class="[theme, {'mvt-cardproperty-new': serverTreatments['movoto-propertycard-redesign-CW-2392'] === 'on'}]"
        @click="openDpp"
        v-ga:clickable="'card'"
        :data-link-name="glb.pageType === 'vmapsearch' && data.isActive && data.threeDTourLink ? '3D Card': 'Card'"
        :data-id="data.propertyId"
        :mark-id="'mark0_' + data.geo.lat + '_' + data.geo.lng"
        :data-provider="data.showProvidedBy && data.mlsName ? `${molang('search.card.providedBy')} ${data.mlsName}` : null">
        <propertyCardSchema :data='data' v-if="showSchema"></propertyCardSchema>
        <div class="mvt-cardproperty-photo" :data-mls="data.formatMlsInfo || null">
            <mvtPlayer v-if="hoverSlideshowEnabled && data.photoCount > 1"
            :title="data.geo.formatAddress"
            :alt="data.geo.formatAddress"
            :data="[data.tnImgPath]"
            :lazyload="fetchListingDetails"
            :showControl="false"></mvtPlayer>
            <mvtMapStatic v-else-if="showStreetView && data.photoCount===0"
                type="streetview"
                streetViewDefautType="satellite"
                :lat="data.geo.lat"
                :lng="data.geo.lng"
                :address="data.geo.formatAddress"
                :title="data.geo.formatAddress"
                :alt="data.geo.formatAddress"
                :width="staticMapData.width"
                :height="staticMapData.height"
                :cacheFirst="true">
                </mvtMapStatic>
            <div v-else :data-alt="data.photoCount > 0 ? null : 'Photos coming soon'" >
                <img loading="lazy" :src="data.tnImgPath" :alt="data.photoCount > 0 ? data.geo.formatAddress : 'Photos coming soon'" />
            </div>
            <button class="btn-3d small" type="button" v-ga:clickable link-text="Video"  @click.stop="openDpp" v-if="data.isVideoTour && serverTreatments['movoto-propertycard-redesign-CW-2392'] === 'on'">
                <img loading="lazy" :src="glb.cdnUrl + 'vimages/videotour.svg'">
            </button>
            <SplitTreatments :names="['movoto-msp-3Dtour-pill-CW-2197']" v-else-if="glb.pageType === 'vmapsearch' && data.isActive && data.threeDTourLink">
                <template #default="{ treatments, isReady }">
                    <button class="btn-3d small" :class="{'bg-white circle': serverTreatments['movoto-propertycard-redesign-CW-2392'] !== 'on'}" type="button" v-ga:clickable data-link-name="3D"  @click.stop="openDpp" v-if="isReady && (treatments['movoto-msp-3Dtour-pill-CW-2197'] === 'on')">
                        <img loading="lazy" :src="glb.cdnUrl + 'vimages/home_animate.svg'">
                    </button>
                 </template>
            </SplitTreatments>
        </div>
        <div class="mvt-cardproperty-info">
            <div class="property-status" v-if="serverTreatments['movoto-propertycard-redesign-CW-2392'] === 'on' && getHouseCardStatusTextAndClass.text">
                <span :class="getHouseCardStatusTextAndClass.className">
                    <i class="icon-union" v-if="getHouseCardStatusTextAndClass.className === 'offmarket'">
                        <span class="path1"></span>
                        <span class="path2"></span>
                    </i>
                    <i v-else class="circle pulse"></i>
                    <span>{{getHouseCardStatusTextAndClass.text}}</span>
                </span>
            </div>
            <div class="price" v-if="data.price && serverTreatments['movoto-propertycard-redesign-CW-2392'] === 'on'">
                {{ $filters.formatPrice(data.price) }}<abbr title="Monthly Rent" v-if="data.isRentals">/Mo</abbr><abbr title="Estimate Vaule" v-if="data.isEstPrice"> Est.</abbr>
            </div>
            <div class="type-and-address" v-if="serverTreatments['movoto-propertycard-redesign-CW-2392'] === 'on'">
                <span>
                    <i :class="data.propertyTypeIcon" class="property-type-icon" :title="data.propertyTypeDisplayName"></i>
                    <span class="propertyType">{{getHouseCardPropertyType }}</span>
                </span>
                <a :href="cardHref" target="_blank" @click.stop="openDpp" tabindex="-1">
                    <address><span>{{data.geo.address}}</span><span>{{getSubAddress}}</span></address>
                </a>
                <img v-if="data.mlsLogoSrc" loading="lazy" class="img-mls" :alt="data.mlsDescription" :src="data.mlsLogoSrc"/>
            </div>
            <div v-else>
                <a :href="cardHref" target="_blank" @click.stop="openDpp" tabindex="-1">
                    <address><span>{{data.geo.address}}</span><span>{{getSubAddress}}</span></address>
                </a>
                <sub v-if="getHouseCardTitleText" v-html="getHouseCardTitleText"></sub>
                <img v-if="data.mlsLogoSrc" loading="lazy" class="img-mls" :alt="data.mlsDescription" :src="data.mlsLogoSrc"/>
            </div>
            <ul :data-viewed="serverTreatments['movoto-propertycard-redesign-CW-2392'] !== 'on' && isViewed">
                <li class="price" v-if="data.price && serverTreatments['movoto-propertycard-redesign-CW-2392'] !== 'on'">
                    {{ $filters.formatPrice(data.price) }}<abbr title="Monthly Rent" v-if="data.isRentals">/Mo</abbr><abbr title="Estimate Vaule" v-if="data.isEstPrice"> Est.</abbr>
                </li>
                <template v-if="!data.isLand">
                    <li>
                        {{data.bed || '—'}}<abbr title="Bedroom">{{serverTreatments['movoto-propertycard-redesign-CW-2392'] === 'on' && theme!=='style3' ? 'Bed': 'Bd'}}</abbr>
                    </li>
                    <li>
                        {{data.bath || '—'}}<abbr title="Bathroom">{{serverTreatments['movoto-propertycard-redesign-CW-2392'] === 'on' && theme!=='style3' ? 'Bath': 'Ba'}}</abbr>
                    </li>
                </template>
                <li v-if="data.area">
                    {{$filters.formatArea(data.area, data.isLand)}}<abbr :title="data.areaUnit">{{data.areaUnit}}</abbr>
                </li>
                <template v-if="!data.isRentals && serverTreatments['movoto-propertycard-redesign-CW-2392'] === 'on'">
                    <li class="sub pricePerArea" v-if="(data.isLand || data.isCondo) && data.pricePerArea">
                        ({{$filters.formatPricePerArea(data.pricePerArea, data.isLand)}}/Sqft)
                    </li>
                    <li class="sub lotSize" v-else-if="data.lotSize">
                        ({{$filters.formatFriendlyLotSize(data.lotSize)}})
                    </li>
                </template>
                <template v-else-if="!data.isRentals">
                    <li class="sub" v-if="(data.isLand || data.isCondo) && data.pricePerArea">
                        {{$filters.formatPricePerArea(data.pricePerArea, data.isLand)}}
                    </li>
                    <li class="sub" v-else-if="data.lotSize">
                        {{$filters.formatFriendlyLotSize(data.lotSize)}}
                    </li>
                </template>
            </ul>
        </div>
        <div class="tag-panel" v-if="serverTreatments['movoto-propertycard-redesign-CW-2392'] === 'on'">
            <div class="tag-group left" tabindex="-1">
                <span class="tag custom new-construction-tag" v-if="isNewConstruction"><span>New Construction</span></span>
                <span class="tag custom ready-to-build-tag" v-else-if="isReadyToBuild"><span>Ready To Build</span></span>
                <span class="tag custom new-tag" v-else-if="isNewTag"><span>{{ molang('search.card.newAgo') }}</span><span>{{ data.createTimeDisplay.replace('Minutes', 'Min') }}</span></span>
                <span class="tag custom sold-tag" v-else-if="isSoldTag"><span>{{ data.houseRealStatusDisplay }}</span><span v-if="data.showSoldDate">{{ $filters.formatDate(data.soldDate, {year: '2-digit'}) }}</span></span>
                <span class="tag custom open-tag" v-else-if="isOpenTag"><span>{{molang('search.card.open')}}</span><span>{{ data.openDateV1 }}</span></span>
                <span class="tag custom pricecut-tag" v-else-if="isPriceCutTag">
                    <span :class="data.isPriceReduced?'price-reduced':'price-increased'">
                        <i :class="{'icon-arrow-down-circle-filled': data.isPriceReduced,'icon-send-ios': !data.isPriceReduced}"></i>
                        {{$filters.formatFriendlyPrice(data.priceChange)}}
                    </span>
                </span>
                <span class="tag custom hot-tag" v-else-if="isHotTag">
                    <span>
                        <img :src="glb.cdnUrl + 'vimages/fire-filled.svg'">
                        HOT
                    </span>
                </span>
                <span class="tag custom listingby-movoto-tag" v-else-if="isListingByMovoto"><span>{{ molang('dpp.propertyNearby.listedBy') }}</span></span>
            </div>
            <div class="tag-group right">
                <button v-if="showShare" type="button" aria-label="Share Property"  @click="sendEmail(data)" class="share circle small bg-white " ><i class="icon-share-ios"></i></button>
                <span v-if="showLeadButton" aria-label="select Property" class="circle small" :class="selectedCard ? 'bg-green' : 'bg-white'"><i :class="{'icon-check': selectedCard}"></i></span>
                <button v-else type="button" aria-label="Favorite Property" @click.stop="favoriteAction" class="favorite circle small bg-white" title="Save"><i class="icon-heart-o" :class="{'active':isFavorite}"></i></button>
            </div>
        </div>
        <div class="tag-panel" v-else>
            <div class="tag-group left" tabindex="-1">
                <span class="tag small" :class="data.labelClass" v-if="data.labelClass">{{data.labelName}}</span>
                <span class="tag small brand active" v-if="data.openDate"> {{molang('search.card.open')}} {{data.openDate}}</span>
                <span class="tag small danger active" v-if="data.isHot">HOT</span>
                <span class="tag small brand active" v-if="data.isCommingSoonListing">Coming Soon</span>
                <span class="tag small brand active" :class="{'hide': glb.pageType == 'vmymovoto_savedhomes' && data.labelName == 'Active Under Contract'}" v-if="data.showPriceChange && !data.hideDaysOnMarket">
                    <i :class="{'icon-trend-down': data.isPriceReduced,'icon-trend-up': !data.isPriceReduced}"></i>
                    {{$filters.formatFriendlyPrice(data.priceChange)}}
                </span>
                <span class="tag small danger active" v-if="false && data.rentalPotential">Investment Opportunity</span>
            </div>
            <div class="tag-group right">
                <button v-if="showShare" type="button" aria-label="Share Property"  @click="sendEmail(data)" class="share circle small bg-white " ><i class="icon-share-ios"></i></button>
                <span v-if="showLeadButton" aria-label="select Property" class="circle small" :class="selectedCard ? 'bg-green' : 'bg-white'"><i :class="{'icon-check': selectedCard}"></i></span>
                <button v-else type="button" aria-label="Favorite Property" @click.stop="favoriteAction" class="favorite circle small bg-white" title="Save"><i class="icon-heart-o" :class="{'active':isFavorite}"></i></button>
            </div>
        </div>
    </article>

</template>
<script src="./mvt-cardproperty.js"></script>
