import { mapGetters } from 'vuex';
import mvtShifter from '@/common/components/mvtshifter/mvt-shifter.vue';
import format from '@/common/utilities/format';

export const getKeyInsights = (dpp, compareData) => {
    let insights = [];
    compareData.length &&
        compareData.map((item) => {
            if (item && item.key === 'price' && dpp.isActive && dpp.price && dpp.price < item.avg) {
                insights.push({
                    title: 'lower list price',
                    sub:
                        (dpp.isEstPrice || dpp.showAsPR ? 'Estimated' : 'Listed') +
                        ' for $' +
                        format.formatNumberByComma(item.value) +
                        ', ' +
                        item.priceDiff +
                        ' less (' +
                        item.percentage +
                        '%) than nearby homes',
                    icon: 'icon-investment',
                    scrollInto: 'marketTrendCompare',
                    order: 1,
                });
            } else if (item && item.key === 'pricePerArea' && dpp.isActive && dpp.pricePerArea && dpp.pricePerArea < item.avg && dpp.area) {
                const betterSqFt = dpp.area - dpp.price / item.avg;
                insights.push({
                    title: 'higher value',
                    sub: item.percentage + '% more valuable and ' + Math.round(betterSqFt) + ' more sqft for the same price',
                    icon: 'icon-money-sqft',
                    value: '$/sqft',
                    scrollInto: 'propertyMarketPanel',
                    order: 2,
                });
            } else if (item && item.key === 'hoafee' && dpp.hoafee && dpp.hoafee < item.avg) {
                const diffHoa = item.avg - dpp.hoafee;
                insights.push({
                    title: 'lower HOA',
                    sub: `$${dpp.hoafee} HOA fees, $${Math.round(diffHoa)} less (${item.percentage}%) than nearby homes`,
                    icon: 'icon-money',
                    scrollInto: 'marketTrendCompare',
                    order: 3,
                });
            } else if (item && item.key === 'area' && dpp.area && dpp.area > item.avg) {
                const diffArea = dpp.area - item.avg;
                insights.push({
                    title: 'larger home',
                    sub: `${format.formatNumberByComma(dpp.area)} sqft, ${format.formatNumberByComma(diffArea)} sqft larger interior (${item.percentage}%) than nearby homes`,
                    icon: 'icon-house',
                    scrollInto: 'marketTrendCompare',
                    order: 4,
                });
            } else if (item && item.key === 'lotSize' && dpp.propertyTypeDisplay === 'Single Family' && dpp.lotSize && dpp.lotSize > item.avg) {
                const diffLotSize = dpp.lotSize - item.avg;
                insights.push({
                    title: 'large lot size',
                    sub: `${format.formatNumberByComma(dpp.lotSize)} sqft lot, ${format.formatNumberByComma(diffLotSize)} sqft larger lot (${item.percentage}%) than nearby homes`,
                    icon: 'icon-property-lot',
                    scrollInto: 'marketTrendCompare',
                    order: 5,
                });
            } else if (item && item.key === 'yearBuilt' && dpp.yearBuilt && dpp.yearBuilt - item.avg >= 2) {
                let diffYears = dpp.yearBuilt - item.avg;
                insights.push({
                    title: 'newer home',
                    sub: `Built in ${dpp.yearBuilt}, ${diffYears} years newer than nearby homes`,
                    icon: 'icon-home-heart',
                    scrollInto: 'marketTrendCompare',
                    order: 6,
                });
            } else if (item && item.key === 'bed' && dpp.bed && dpp.bed > item.avg) {
                const bedDiff = Math.floor(dpp.bed - item.avg);
                if (bedDiff >= 1) {
                    insights.push({
                        title: 'more bedrooms',
                        sub: `${dpp.bed} bedrooms, ${bedDiff}  ${bedDiff > 1 ? 'bedrooms' : 'bedroom'} more than nearby homes`,
                        icon: 'icon-bed',
                        scrollInto: 'marketTrendCompare',
                        order: 7,
                    });
                }
            } else if (item && item.key === 'bath' && dpp.bath && dpp.bath > item.avg) {
                const bathDiff = Math.floor(dpp.bath - item.avg);
                if (bathDiff >= 1) {
                    insights.push({
                        title: 'more bathrooms',
                        sub: `${dpp.bath} bathrooms, ${bathDiff}  ${bathDiff > 1 ? 'bathrooms' : 'bathroom'} more than nearby homes`,
                        icon: 'icon-bath',
                        scrollInto: 'marketTrendCompare',
                        order: 8,
                    });
                }
            }
        });
    if (dpp.marketTrendTable) {
        if (dpp.marketTrendTable.city && dpp.marketTrendTable.city.allTypeMedianListPrice && dpp.price) {
            const trend = dpp.price > dpp.marketTrendTable.city.allTypeMedianListPrice ? 'above' : 'below';
            const useEstimated = dpp.isEstPrice || dpp.showAsPR;
            const price = useEstimated ? dpp.estPrice || dpp.price : dpp.price;
            const diff = price > dpp.marketTrendTable.city.allTypeMedianListPrice ? price - dpp.marketTrendTable.city.allTypeMedianListPrice : dpp.marketTrendTable.city.allTypeMedianListPrice - price;
            const diffPerc = (diff / dpp.marketTrendTable.city.allTypeMedianListPrice) * 100;
            insights.push({
                title: `${trend} market median`,
                sub: `${useEstimated ? 'Estimated' : 'Listed'} for $${format.friendlyPrice(price)},  $${format.friendlyPrice(diff)} (${Math.round(diffPerc)}%) ${trend} ${dpp.geo.city} median`,
                icon: `icon-trend-${dpp.price > dpp.marketTrendTable.city.allTypeMedianListPrice ? 'up' : 'down'}`,
                scrollInto: 'propertyMarketPanel',
                order: 10,
            });
        } else if (dpp.marketTrendTable.zipcode && dpp.marketTrendTable.zipcode.allTypeMedianListPrice && dpp.price) {
            const trend = dpp.price > dpp.marketTrendTable.zipcode.allTypeMedianListPrice ? 'above' : 'below';
            const useEstimated = dpp.isEstPrice || dpp.showAsPR;
            const price = useEstimated ? dpp.estPrice || dpp.price : dpp.price;
            const diff =
                price > dpp.marketTrendTable.zipcode.allTypeMedianListPrice ? price - dpp.marketTrendTable.zipcode.allTypeMedianListPrice : dpp.marketTrendTable.zipcode.allTypeMedianListPrice - price;
            const diffPerc = (diff / dpp.marketTrendTable.zipcode.allTypeMedianListPrice) * 100;
            insights.push({
                title: `${trend} market median`,
                sub: `${useEstimated ? 'Estimated' : 'Listed'} for $${format.friendlyPrice(price)},  $${format.friendlyPrice(diff)} (${Math.round(diffPerc)}%) ${trend} ${dpp.geo.zipcode} median`,
                icon: `icon-trend-${dpp.price > dpp.marketTrendTable.zipcode.allTypeMedianListPrice ? 'up' : 'down'}`,
                scrollInto: 'propertyMarketPanel',
                order: 10,
            });
        }
        if (dpp.marketTrendTable.city) {
            if (dpp.marketTrendTable.city.allTypeMedianDom && dpp.daysOnMovoto) {
                const itemIcon = 'icon-calendar-hole';
                insights.push({
                    title: 'days on market',
                    sub: `${dpp.daysOnMovoto} days on the market, compared to ${dpp.marketTrendTable.city.allTypeMedianDom} days typically spent now`,
                    icon: itemIcon,
                    value: 'dom',
                    scrollInto: 'propertyMarketPanel',
                    order: 9,
                });
            }
        }
    }

    return insights.sort(function (a, b) {
        return a.order - b.order;
    });
};

export default {
    name: 'dppKeyInsights',
    components: {
        mvtShifter,
    },
    inject: ['$eventBus'],
    props: {
        compareData: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    methods: {
        ...mapGetters('glb', ['getComplianceMethod']),
        showDisclaimerOnKeyInsights() {
            const compliance = this.getComplianceMethod()();
            return compliance[0] && compliance[0].rule.indexOf('showDisclaimerOnKeyInsights') >= 0;
        },
        get1YearAgoValue(curr, perc) {
            let val = (100 * curr) / (100 + parseInt(perc));
            return Math.round(val);
        },
        changeTab(value) {
            setTimeout(() => {
                this.$eventBus.$emit('proeprty-market-change', value || 'price');
            }, 10);
        },
    },
    computed: {
        ...mapGetters('glb', ['glb']),
        ...mapGetters('dpp', ['dpp']),
        getKeyInsights() {
            return getKeyInsights(this.dpp, this.compareData);
        },
    },
};
