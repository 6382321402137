class AppShare {

  isWebViewMode() {
    return typeof $ === 'undefined' ? false : $.getCookie('mode') === 'webview';
  }

  registerAppShareHandlers(propertyId = null) {
    window.androidObj = {
      eventToNative(message) {
        // trigger interface defined in native Android app
        return javascript_obj.eventFromWeb(message);
      }
    };
    window.receiveEventFromNative = function(event, params) {
      event = 'sharePage';
      switch (event) {
        case 'sharePage':
          return { event: event, url: window.location.href };
        default:
          const result = $(document).trigger(`webviewevent.${event}`, params);
          return result && { event };
      }
    };
    $(document).on('webviewevent.dppInfo', () => {
      return {
        url: window.location.href,
        propertyId: propertyId
      };
    });
  }

  sentNativeEventToAPP(osType, event, data) {
    const jsonData = JSON.stringify(data);
    switch (osType) {
      case 'iOS':
        window.webkit && window.webkit.messageHandlers
          && window.webkit.messageHandlers.nativeCallbackHandler
          && window.webkit.messageHandlers.nativeCallbackHandler.postMessage
          && window.webkit.messageHandlers.nativeCallbackHandler.postMessage({
            event: event,
            data: jsonData
          });
        break;
      case 'AndroidOS':
        window.androidObj && window.androidObj.eventToNative
          && window.androidObj.eventToNative([event, jsonData]);
        break;
      default:
        console.warning('Unsupported OS type');
    }
  }
}

export const appShare = new AppShare();