<template>
    <section>
        <div class="home-qrcode grid fixed xs-grid-cols-1">
            <div class="home-qrcode-img">
                <a  @click="openAppInstall" class="btn primary regular hidden-sm hidden-md hidden-lg" role="button">
                    <span>Open the App</span>
                </a>
                <div class="hidden-xs">
                    <a v-if="glb.extendedOS ==='iOS' || glb.extendedOS ==='MacOS'" href="https://apps.apple.com/app/apple-store/id537795977?pt=469170&ct=movoto_homepage_qr&mt=8" target="_blank" title="Movoto Real Estate iOS App">
                        <img  loading="lazy" alt="Download APP"  :src=" glb.cdnUrl + 'vimages/qrcode.svg'"/>
                    </a>
                    <a v-else href="https://play.google.com/store/apps/details?id=com.movoto.movoto&utm_campaign=movoto_homepage_qr" target="_blank" title="Movoto Real Estate Android App">
                        <img  loading="lazy" alt="Download APP" :src=" glb.cdnUrl + 'vimages/qrcode.svg'"/>
                    </a>
                    <div class="f7 text-gray">Scan to Download App</div>
                </div>
            </div>
            <div class="home-qrcode-txt">
                <h3 class="f3 text-bold">
                    Download the Movoto App Today
                </h3>
                <div class="text-gray m-t-1">We're here to help you find your dream home, offer property insights,
                    and connect you with top local agents on iOS and Android.</div>
            </div>
            <div class="home-qrcode-photo">
                <div>
                    <img class="hidden-md hidden-sm hidden-lg" loading="lazy" title="Movoto real estate app" alt="Movoto real estate app" :src="glb.cdnUrl+'vimages/qrcode-app-mobile-new.png'">
                    <img class="hidden-xs" loading="lazy" title="Movoto real estate app"  alt="Movoto real estate app" :src="glb.cdnUrl+'vimages/qrcode-app-desktop.png'">
                </div>
            </div>
        </div>
    </section>
</template>

<script src="./home-qrcode.js"></script>

<style lang="scss">
    @import "./home-qrcode.scss";
</style>


