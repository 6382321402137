<template>
  <picture>
    <source :srcset="srcset" type="image/webp">
    <img :src="src" :class="classes" fetchPriority="auto" :alt="alt" />
  </picture>
</template>

<script src="./mvt-picture.js"></script>
<style lang="scss">
@import "./mvt-picture.scss";
</style>