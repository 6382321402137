import {mapGetters} from 'vuex';
import mvtCollapsibleLinks from "@/common/components/mvtcollapsiblelinks/mvt-collapsiblelinks.vue";
export default {
    name: 'homeLinks',
    components: {
        mvtCollapsibleLinks,
    },
    data() {
        return {
            showMore: [false,false,false]
        };
    },
    computed: {
        ...mapGetters('glb', ['glb']),
        ...mapGetters('home', ['home']),
        stateList() {
            return (this.home.stateList || []).map((state) => {
                return {
                    text: state.displayName,
                    url: `${this.glb.appUrl}${state.sitemapStatePageUrl}`,
                };
            });
        },
    },
};
