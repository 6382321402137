export default {
    "WelcomeHome": "Jump-Start Your",
    "WelcomeHome2": "Real Estate Journey",
    "WelcomeHomeNew": "Let's open doors together.",
    "WhoWeAre": "For Homebuyers",
    "WhoWeAreText": "Our approach is rooted in a deep understanding of your needs. We support you with tools, expert guidance, and tailored resources, then connect you with a top local Real Estate agent, so you're confident and equipped for each step.",
    "WhatWeDo": "For Sellers",
    "WhatWeDoText": "Transparent guidance, data-driven insights, and thoughtful matching with the right top local Real Estate agent, equip you with the knowledge you need to monitor your home's equity, identify when to sell, and maximize your home sale.",
    "WhyWorkWithUs": "For Homeowners",
    "WhyWorkWithUsText": "With personalized guidance and intuitive tools, you can make informed decisions around life’s biggest asset — your home. Easily monitor your home’s market value; find savings on taxes, mortgages, and insurance; and build long-term wealth.",
    "AllInOnePlace": "All in One Place",
    "AllInOnePlaceTitle": "Your One-Stop Shop for Finding Your Dream Home",
    "AllInOnePlaceText": "Join the 350,000 daily users who have relied on Movoto to find the perfect home. Search for properties, neighborhoods, comparables, open houses, and more — all in one easy place.",
    "SearchForHomes": "Search for Homes",
    "buy": "Buy",
    "buyAHome": "Buy a Home",
    "ConnectAgent": "Connect with an Agent",
    "ConnectAgentTitle": "Schedule a Free, No-Obligation Appointment",
    "ConnectAgentText": "Let one of our top local agents help you buy or sell a home. We screen all our agents to ensure they have the market expertise and drive to make your home transaction a success.",
    "FindAnAgent": "Find an Agent",
    "HomeValuation": "How Much is My House Worth",
    "HomeValuationTitle": "Understand the Value of Your Property",
    "HomeValuationText": "Our recently updated algorithms make sure that we’re pulling the most relevant comparables and latest market data to give you the most accurate valuation for your home.",
    "AreYouAgent": "Are you a real estate agent?",
    "AreYouAgentTitle": "Join the Movoto Select Network and start working with more clients today",
    "AreYouAgentText": "If you’re a high-performing agent with a great track record of taking care of your clients, we’d love to introduce you to motivated buyers and sellers.",
    "ApplyNow": "Apply Now",
    "Testimonial": "Testimonial",
    "GetStarted": "Get Started Now",
    "SeeHomes": "See Homes",
    "ConnectwithanAgent": "Connect with an Agent",
    "ViewOwnerDashboard": "View Owner Dashboard",
    "County": "County",
    "TestimonialTitle": "What Customers Are Saying",
    "TestimonialText": "Frank Wong, our Bay Area Oakland Movoto Agent, explains how he helped his client sell their existing home and purchase a new home while negotiating $7500 worth of builder credits. His clients original home had an offer within 7 days!",
    "Buy": "Homes",
    "BuyText": "City, ZIP, School, Address, Neighborhood...",
    "BuyTip": "We're here for all of your home buying and selling needs.",
    "Recently": "Recently",
    "Sold": "Sold",
    "SoldText": "City, ZIP, neighborhood, school, address",
    "SoldTip": "The latest market insights for your next step",
    "PriceMyHome": "Price My Home",
    "PriceMyHomeText": "Enter home address...",
    "sellMyHomeText": "My Home Address",
    "PriceMyHomeText1": "Home address",
    "PriceMyHomeTextMobile": "Home address",
    "PriceMyHomeTip": "Move forward with knowledge and confidence",
    "NewestTitle": "THE LATEST LISTINGS",
    "NewestDesp": "New Listings for Sale in",
    "NewestDespRent": "New Rentals in",
    "MovotoOjo1": "Movoto is excited to join OJO Labs",
    "MovotoOjo2": "Together we will transform the way people buy and sell homes forever",
    "MovotoBotText": "MOVOTO REAL ESTATE LISTINGS",
    "MovotoBotTitle": "Welcome Home",
    "RealEstateListings": "REAL ESTATE LISTINGS",
    "OpenDesp": "Open Houses in",
    "ReduceDesp": "Price Reductions in",
    "RecentTitle": "MY SEARCHES",
    "RecentDesp": "Recently Viewed",
    "HotleadTitle": "Connect with an Agent",
    "hotleadSub": "We'll match you with a top local Real Estate agent, best suited to your home journey needs.",
    "HotleadButtionCap": "Connect Now",
    "pageTitle": "Real Estate, Homes for Sale, MLS Listings | Movoto",
    "pageDesp": "Movoto provides customizable search tools, local market insights, and expert guidance to support you on your path toward homeownership.",
    "NewListings": "New Listings by State",
    "ListingsByState": "Listings by State",
    "AllRealEstate": "All Listings by State",
    "PopularCities": "Popular Cities",
    "RealEstate": "Real Estate",
    "HomesforSale": 'Homes for Sale',
    "searchPlaceholder": "Enter a home address",
    "learnMore": "Learn More",
    "homeEstimate": "Home Estimate",
    "homeEst": "Home Estimate",
    "sell": "Sell",
    "sellHome": "Sell My Home",
    "sellOffer": "Sell / Cash Offer",
}