import { mapGetters } from 'vuex';
import mvtShifter from '@/common/components/mvtshifter/mvt-shifter.vue';

var hotleadTypes = ['InlineHotleadInResponsiveDPP', 'VirtualTourHotleadInResponsiveDPP'];

export default {
    name: 'mvtCalendar',
    inject: ['$eventBus'],
    emits: ['update:modelValue'],
    components: {
        mvtShifter
    },
    props: {
        icon: {
            type: String,
            default: 'icon-property-single-family'
        },
        optionalDate: {
            type: Boolean,
            required: false,
            default: false,
        },
        modelValue: {
            type: Object,
            default: function(){
                return {
                    hotleadType: undefined,
                    date: undefined,
                    timeDisplay: undefined
                };
            }
        },
        maxDay: {
            type: Number,
            default: 14
        },
        maxHour: {
            type: Number,
            default: 20
        }
    },
    data() {
        return {
            activeTimeIndex: 0,
            activeDateIndex: this.optionalDate ? undefined : 0,
            activeTypeIndex: 0,
            disableMorning: false,
            disableToday: false,
            dateList: []
        };
    },
    beforeMount() {
        this.populateDateList();
    },
    created() {
        this.populateDateList();
    },
    mounted() {
        if(this._value.date && this._value.date.toDateString){
            let key = this._value.date.toDateString();
            var dateIndex = this.dateList.findIndex(e=>e.key === key);
            if(dateIndex >= 0){
                this.activeDateIndex = dateIndex;
            }
        }
        if(this.modelValue.hotleadType && hotleadTypes.indexOf(this.modelValue.hotleadType) >= 0){
            this.activeTypeIndex = hotleadTypes.indexOf(this.modelValue.hotleadType);
        }
        this.checkToday();
        this.updateValue();
    },
    methods: {
        populateDateList() {
            let dateList = [];
            for (let i = 0; i < this.maxDay; i++) {
                let date = new Date();
                date.setDate(date.getDate() + i);
                let key = date.toDateString();
                let dayMonthDateArr = key.split(' ');
                dateList.push({
                    index: i,
                    raw: date,
                    key: key,
                    day: dayMonthDateArr[0],
                    month: dayMonthDateArr[1],
                    dateNum: dayMonthDateArr[2]
                });
            }
            this.dateList = dateList;
        },
        checkToday(){
            let today = new Date();
            var hour = today.getHours();
            this.disableToday = hour >= 18;
            if (this.activeDateIndex === 0) {
                if (!this.disableToday) {
                    if (this.timeList.length < this.activeTimeIndex + 1) {
                        this.activeTimeIndex = this.timeList.length - 1;
                    }
                } else {
                    this.activeDateIndex = 1;
                }
            }
        },
        changeActiveTimeIndex(index){
            if(this.activeTimeIndex !== index){
                this.activeTimeIndex = index;
                this.updateValue();
            }
        },
        changeActiveDateIndex(index){
            if(this.activeDateIndex !== index){
                this.activeDateIndex = index;
                this.checkToday();
                this.updateValue();
            }else if(this.newDpp && this.optionalDate){
                this.activeDateIndex = undefined;
                this.activeTimeIndex = 0;
                this._value = {
                    hotleadType: undefined,
                    date: undefined,
                    timeDisplay: undefined
                };  
            }
        },
        setTypeIndex(index) {
            this.activeTypeIndex = index;
            this.updateValue();
        },
        updateValue(){
            var i = this.activeDateIndex;
            var date;
            if (i >= 0) {
                date = this.dateList.find(function(e) {
                    return e.index === i;
                }).raw;
            }
            var timeDisplay = this.timeList[this.activeTimeIndex];

            this._value = {
                hotleadType: hotleadTypes[this.activeTypeIndex],
                date: date,
                timeDisplay: timeDisplay || ''
            };
        }
    },
    computed: {
        ...mapGetters('glb', ['glb', 'getSplit']),
        ...mapGetters('dpp', ['dpp']),
        newDpp() {
            return this.getSplit('movoto-dpp-redesign-CW-2515', 'on1') || this.getSplit('movoto-dpp-redesign-CW-2515', 'on2');
        },
        _value: {
            get() {
                return this.modelValue;
            },
            set(e) {
                this.$emit('update:modelValue', e);
            }
        },
        timeList() {
            var startHour = 8;
            var currentMinute = new Date().getMinutes();
            var earlier = false;

            if(this.activeDateIndex === 0){
                var currentHour = new Date().getHours() + 2;
                if(currentHour < startHour){
                    earlier = true;
                }else{
                    startHour = Math.max(8, currentHour);
                }
            }
            var list = [];
            for(var i = startHour; i < this.maxHour; i++){
                var affix = i >= 12 ? 'pm' : 'am';
                var time = i > 12 ? i - 12 : i;
                list.push(`${time}:00 ${affix}`);
                list.push(`${time}:30 ${affix}`);
            }
            if(this.activeDateIndex === 0){
                if(list.length > 0){
                    if(!earlier){
                        if(currentMinute > 0 && currentMinute <= 30){
                            list.splice(0, 1);
                        }else if(currentMinute > 30){
                            list.splice(0, 2);
                        }
                    }
                    list.push(`${this.maxHour - 12}:00 pm`);
                }
            } else {
                list.push(`${this.maxHour - 12}:00 am`);
            }
            return list;
        },
        testCalendarView() {
            return this.dpp && this.dpp.isActive;
        }
    }
};