import { getCurrentInstance } from "vue";
export default {
    name: 'mvtRadio',
    emits: ['update:modelValue'],
    props: {
        data: {
            type: Array,
            required: true,
        },
        modelValue: {
            type: Number,
            default: 0,
            required: false,
        },
        theme: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            name: 'mvtRadio' + getCurrentInstance().uid,
        };
    },
    computed: {
        _value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },
    methods: {
        checked(value) {
            return value == this.modelValue;
        },
    }
};
