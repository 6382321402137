import { mapGetters } from 'vuex';

export default {
    name:'mvtCardAgent',
    props: {
        data: {
            type: Object,
            default: null
        },
        simple: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        defaultImg(e) {
            e && e.target && (e.target.src = this.glb.cdnUrl + 'images/desktop/agent/agent-min.png');
        }
    },
    computed: {
        ...mapGetters('glb', ['glb', 'getBrokerageAgents']),
        ...mapGetters('msp', ['geo', 'msp', 'getGeoDisplayText']),
        agent() {
            if (this.data) {
                return {
                    isMovotoAgent: this.data.agentType===1 || this.data.isMovotoAgent,
                    image: (this.data.imageUrl || this.data.agentImage) || this.glb.cdnUrl + 'images/desktop/agent/agent-min.png',
                    name: this.data.fullName,
                    firstname: this.data.firstName,
                    lastname: this.data.lastName,
                    title: this.data.agentTitle,
                    rating: this.data.averageRating || this.data.rating,
                    desc: this.data.highlight,
                    profileUrl: this.data.profileUrl
                }
            }
            return null;
        }
    }
}