
function isNumeric(a) {
    return typeof a === 'number' && !isNaN(a) && isFinite(a);
}

function _getSortPointByKey(current, option) {
    var listing = option.listing;
    var key = option.key;
    var times = option.times || 1;
    var defaultPoint = option.defaultPoint || 1000;
    if (listing[key] && isNumeric(listing[key])) {
        if (current[key] && isNumeric(current[key])) {
            listing[key + 'Rate'] = Math.abs(listing[key] - current[key]) * times;
            return Math.abs(listing[key] - current[key]) * times;
        }
            return defaultPoint;
    }
    return 0;
}

function _comparableSortPoint(listing, current) {
    var point = 0;

    listing.geo = (listing && listing.geo) || {};
    current.geo = (current && current.geo) || {};

    if (current.houseRealStatus === 'SOLD' && current.pricePerArea) {
        return  Math.abs(listing.pricePerArea - current.pricePerArea);
    }

    if (listing.isNHS !== current.isNHS) {
        point += 99999;
    }

    if (listing.geo.lat && listing.geo.lng && listing.geo.lat === current.geo.lat && listing.geo.lng === current.geo.lng) {

    } else {
        point += 2000;
    }

    if (listing.geo.neighborhood && listing.geo.neighborhood === current.geo.neighborhood) { } else{
        point += 500;
    }

    if (listing.geo.zipcode !== current.geo.zipcode) {
        point += 1500;
    }

    if (listing.distance) {
        point += listing.distance * 200;
    }
    point += _getSortPointByKey(current, {
        listing: listing,
        key: 'sqftTotal',
        times: 2,
        defaultPoint: (current.area / 2 || 1500)
    });
    if (current.isCondo) {
        point += _getSortPointByKey(current, {listing: listing, key: 'hoafee', defaultPoint: 300});
    } else {
        point += _getSortPointByKey(current, {listing: listing, key: 'lotSize', times: 0.75, defaultPoint: 500});
    }
    point += _getSortPointByKey(current, {listing: listing, key: 'yearBuilt', defaultPoint: 80});
    if (!current.isPrOnly) {
        point += _getSortPointByKey(current, {listing: listing, key: 'price', times: 0.002});
    }
    return point;
}

function sortBySimilar(list, current) {
    if (!current) {
        return list;
    }
    if (list && list.length) {
        list.forEach(function (e) {
            e.sortValue = _comparableSortPoint(e, current);
        });
        return list.sort(function (a, b) {
            return a.sortValue - b.sortValue;
        });
    }
    return list;
}

export default {
    sortProperty(sort, list, current){
        if (sort == 'similar_desc') {
            return sortBySimilar(list, current);
        } else if (sort == 'distance_asc') {
            return list.sort(function(a, b) {
                var va = a.distance;
                var vb = b.distance;
                if (va === vb) {
                    return 0;
                } else if (!va) {
                    return 1;
                } else if (!vb) {
                    return -1;
                }
                return va < vb ? -1 : 1;
            });
        } else if (sort == 'dom_asc') {
            return list.sort(function (a, b) {
                var va = +new Date(a.createdAt);
                var vb = +new Date(b.createdAt);
                if (va === vb) {
                    return 0;
                } else if (!va) {
                    return 1;
                } else if (!vb) {
                    return -1;
                }
                return va > vb ? -1 : 1;
            });
        } else if (sort == 'price_asc') {
            return list.sort(function(a, b) {
                var va = a.price;
                var vb = b.price;
                if (va === vb) {
                    return 0;
                } else if (!va) {
                    return 1;
                } else if (!vb) {
                    return -1;
                }
                return va < vb ? -1 : 1;
            });
        } else if (sort == 'price_desc') {
            return list.sort(function(a, b) {
                var va = a.price;
                var vb = b.price;
                if (va === vb) {
                    return 0;
                } else if (!va) {
                    return 1;
                } else if (!vb) {
                    return -1;
                }
                return va > vb ? -1 : 1;
            });
        } else if (sort == 'sqft_asc') {
            return list.sort(function(a, b) {
                var va = a.area;
                var vb = b.area;
                if (va === vb) {
                    return 0;
                } else if (!va) {
                    return 1;
                } else if (!vb) {
                    return -1;
                }
                return va < vb ? -1 : 1;
            });
        } else if (sort == 'sqft_desc') {
            return list.sort(function(a, b) {
                var va = a.area;
                var vb = b.area;
                if (va === vb) {
                    return 0;
                } else if (!va) {
                    return 1;
                } else if (!vb) {
                    return -1;
                }
                return va > vb ? -1 : 1;
            });
        } else if (sort == 'pricepersqft_asc') {
            return list.sort(function(a, b) {
                var va = a.pricePerArea;
                var vb = b.pricePerArea;
                if (va === vb) {
                    return 0;
                } else if (!va) {
                    return 1;
                } else if (!vb) {
                    return -1;
                }
                return va < vb ? -1 : 1;
            });
        } else if (sort == 'pricepersqft_desc') {
            return list.sort(function(a, b) {
                var va = a.pricePerArea;
                var vb = b.pricePerArea;

                if (va === vb) {
                    return 0;
                } else if (!va) {
                    return 1;
                } else if (!vb) {
                    return -1;
                }
                return va > vb ? -1 : 1;
            });
        }
        return list;
    }
};
