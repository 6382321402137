<template>
    <section class="home-welcome-presentational section-hero">
        <div class="video-container" v-if="getSplit('movoto-home-header-image-to-vedio-CW-1622') && videoSrc">
          <video
              id="home-video"
              loop muted autoplay playsinline
              :src="videoSrc"
          ></video>
        </div>
        <picture class="bg" :class="{'bg-overlay': getSplit('movoto-home-header-image-to-vedio-CW-1622')}">
            <source :srcset="home.homeImgWebp" media="(max-width: 639px)" type="image/webp">
            <source :srcset="home.homeImgWebpSM" media="(min-width: 640px) and (max-width: 991px)" type="image/webp">
            <source :srcset="home.homeImgWebpMD" media="(min-width: 992px) and (max-width: 1399px)" type="image/webp">
            <source :srcset="home.homeImgWebpLG" media="(min-width: 1400px) and (max-width: 1920px)" type="image/webp">
            <source :srcset="home.homeImgWebpXL" media="(min-width: 1921px)" type="image/webp">
            <img fetchPriority="auto" :src="home.homeImgWebp" alt="Welcome Home Movoto" :srcset="home.homeImgSrcset">
        </picture>
        <div >
            <div class="info">
                <h1 class="f1 text-bold" :class="{'text-center' : isPhone}">
                    {{ labels.welcomeHome }}
                </h1>
                <div class="f6" :class="{'text-center' : isPhone}">
                    {{ labels.homeBuyTip }}
                </div>
                <ul id="searchTab" class="tab tab-bookmark" data-role="tab">
                    <li class="m-r-1">
                        <button type="button" :aria-label="labels.aria.homeBuy" :class="{'active': index==0}" @click="toggle(0)">{{ labels.button.homeBuy }}</button>
                    </li>
                    <li class="m-r-1">
                        <button type="button" :aria-label="labels.aria.homeSell" :class="{'active': index==1}" @click="toggle(1)">{{ labels.button.homeSell }}</button>
                    </li>
                    <li>
                        <button type="button" :aria-label="labels.aria.homeEst" :class="{'active': index==2}" @click="toggle(2)"> {{ labels.button.homeEst }}</button>
                    </li>
                </ul>
                <div v-show="index==0" comp="homeBuy" class="content">
                    <mvtSearchbox :heroMode="true" size="large" :placeholder="labels.homeBuyText" v-model="searchInfo"/>
                </div>
                <div v-show="index==1" comp="homeSell" class="content">
                    <mvtInputGeo ref="sellAddressInput" class="large" btnclass="mvt-searchbox__submit" v-model="sellGeoInfo" :placeholder="labels.homeSellText"/>
                </div>
                <div v-show="index==2" comp="homePriceEst" class="content">
                    <mvtInputGeo ref="addressInput" class="large" btnclass="mvt-searchbox__submit" v-model="geoInfo" :placeholder="labels.homePriceText"/>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import mvtSearchbox from '@/common/components/mvtsearchbox/mvt-searchbox.vue';
import mvtInputGeo from '@/common/components/mvtinputgeo/mvt-inputgeo.vue';
import { mapGetters } from 'vuex';
export default {
    name: 'HomeWelcomePresentational',
    inject: ['$eventBus'],
    props: {
        home: Object,
        isPhone: Boolean,
        labels: Object,
        onChangeSellGeo: Function,
    },
    computed: {
        ...mapGetters('glb', ['viewport', 'glb','getSplit']),
    },
    components: {
        mvtSearchbox,
        mvtInputGeo
    },
    methods: {
        toggle(index) {
            this.index = index;
        },
        setVideoSrc(){
          let size = 'small';
          if(screen.width <= 767){
            size = 'small';
          } else if(screen.width>=768 && screen.width<=995){
            size = 'med';
          } else if(screen.width>=996 && screen.width<=1279){
            size = 'large';
          } else if(screen.width>=1280){
            size = 'hd';
          }
          this.videoSrc = `${this.glb.appUrl}novaimgs/images/desktop/home/MovotoHomeVideoOptimized-${size}.mp4`;
        }
    },
    data() {
        return {
            index: 0,
            searchInfo: {
                text: '',
                geo: null
            },
            sellGeoInfo: {
                text: '',
                geo: null
            },
            geoInfo: {
                text: '',
                geo: null
            },
          videoSrc: null,
        };
    },
    watch: {
        geoInfo(newVal){
            if (newVal){
                this.$eventBus.$emit('eventbus-search-address', {
                    text: newVal.text,
                    geo: newVal.geo,
                    extraPath: '?simple=price'
                });
            }
        },
        searchInfo(newVal){
            if (newVal){
                this.$eventBus.$emit('eventbus-search', {
                    text: newVal.text,
                    geo: newVal.geo,
                    isGoogleGeo: newVal.isGoogleGeo,
                    extraPath: this.index == 1 ? 'sold/' : (newVal.extraPath && `${newVal.extraPath}/`),
                    isMlsNumber: newVal.isMlsNumber
                });
            }
         },
         sellGeoInfo(newVal){
            if (newVal){
                this.onChangeSellGeo(newVal);
            }
        },
    },
    mounted() {
        if (location.href.indexOf('select=pricemyhome') >= 0){
            this.toggle(2);
        }
        this.setVideoSrc();
    }
};
</script>
<style lang="scss">
@import "~@/common/scss/base/_defined.scss";
    .home-welcome-presentational {
        .mvt-searchbox__submit i {
            transform-origin: initial !important;
        }

        .video-container {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          max-width: 100%;
          margin-left: 0;
          margin-right: 0;

          video {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit : cover;
            @media screen and (min-width:$screen-md-min){
                object-position: top;
            }
          }

          &:after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: #0E6959;
            opacity: 20%;
          }

        }

        .bg-overlay {
          opacity: 20%;
        }

    }
</style>