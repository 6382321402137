export default {
    name: 'MvtCollapsibleLinks',
    props: {
        title: String,
        titleTag: {
            type: String,
            default: 'h2',
        },
        titleClass: {
            default: {},
        },
        links: Array,
        initialExpandedState: {
            type: Boolean,
            default: false
        },
        limit: {
            type: Number,
            default: 12
        },
        numCols: {
            type: Number,
            default: 3,
        },
        forceExpanded: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            showMore: false
        }
    },
    computed: {
        isExpanded() {
            if(this.forceExpanded) {
                return true;
            }
            return this.showMore;
        },
    },
    methods:  {
        toggle(){
            this.showMore = !this.showMore;
        },
        isLinkList(item) {
            return Array.isArray(item) || item.multipart;
        },
    },
    mounted() {
        this.showMore = this.initialExpandedState;
    }

}