import { mapGetters } from 'vuex';

export default {
    name: 'homeQRCode',
    inject: ['$eventBus'],
    computed: {
        ...mapGetters('glb', ['glb', 'viewedListings']),
        ...mapGetters('home', ['home']),
    },
    methods: {
        openAppInstall() {
            this.$eventBus.$emit('ga', { ec: 'CAPP', en: 'app_home_qr_click', el: 'app-homeQAOpen' });
            if (this.glb.OS === 'iOS') {
                window.open('https://apps.apple.com/app/apple-store/id537795977?pt=469170&ct=movoto.com%20homepage&mt=8');
            } else {
                window.open('https://play.google.com/store/apps/details?id=com.movoto.movoto&utm_campaign=movotohomepage');
            }
        },
    },
};
