import { mapGetters } from 'vuex';
import mvtNearby from '@/common/components/mvtnearby/mvt-nearby.vue';
import mvtLazyload from '@/common/components/mvtlazyload/mvt-lazyload.vue';

export default {
    name: 'homeNearbyNew',
    components: {
        mvtNearby,
        mvtLazyload,
    },
    computed: {
        ...mapGetters('glb', ['glb', 'getCityNewListings']),
        ...mapGetters('home', ['home']),
    },
    methods: {
        completed(data) {
            if (!data || !data.length) {
                window &&
                    window.sendNodeLog('VueNewHomesZeroState', window.location.pathname, undefined, {
                        city: this.home.geo.cityId,
                    });
            }
            this.$refs.lazyload.ready();
        },
    },
};
