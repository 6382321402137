export default {
    name: 'mvtCheckbox',
    emits: ['update:modelValue'],
    props: {
        data: {
            type: Array,
            required: true,
        },
        modelValue: {
            default: [],
        },
        theme: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        wrap: {
            type: Boolean,
            default: true,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
    },
    created() {
        //check it's mupltilpe checkbox
        //for single case data should be [{value:true,defaultValue:false}]
        this.isSingle = this.data.length === 1;
    },
    computed: {
        active() {
            return this.isSingle && this.modelValue === this.data[0].value;
        },
    },
    methods: {
        checked(value, defaultValue) {
            if (this.isSingle) {
                return this.modelValue === value ? 1 : 0;
            }
            if (defaultValue) {
                return this.modelValue.indexOf(value) > -1 ? 0 : 1;
            }
            return this.modelValue.indexOf(value) > -1 ? 1 : 0;
        },
        changed(e) {
            if (this.isSingle) {
                this.$emit('update:modelValue', e.target.checked ? this.data[0].value : this.data[0].defaultValue);
            } else {
                var checkList = this.modelValue.concat([]);

                let opt = this.data.find((item) => {
                    return item.value === e.target._value;
                });

                let checked = e.target.checked;
                if (opt && opt.defaultValue) {
                    checked = !checked;
                }

                if (checked) {
                    checkList.push(e.target._value);
                } else {
                    checkList.splice(checkList.indexOf(e.target._value), 1);
                }
                this.$emit('update:modelValue', checkList);
            }
        },
    },
};
