<template>
    <section>
        <div class="grid fixed xs-grid-cols-1">
            <template v-if="home?.seoOpenListings">
                <div class="text-center">
                    <h2 class="f3 text-bold">
                        Open Houses
                    </h2>
                </div>
                <mvtNearby :data="home?.seoOpenListings" cardFilter="openHouse" carousalType="nationwide_open_house"></mvtNearby>
            </template>
            <template v-else>
                <div class="text-center">
                    <h2 class="f3 text-bold">
                        {{molang('home.OpenDesp')}} <a :href="glb.appUrl + home.geo.openHousePath " class="link">{{home.geo.city}}</a>
                    </h2>
                </div>
                <mvtLazyload ref="lazyload" :async="true" data-role="propertycard">
                    <mvtNearby cardFilter="openHouse" type="city" filterPath="/single-family/open-houses/" @completed="completed" carousalType="open_house"></mvtNearby>
                </mvtLazyload>
            </template>
        </div>
    </section>
</template>

<script src="./home-nearbyopen.js"></script>
