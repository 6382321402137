export default {
    name: 'mvtSelect',
    emits: ['update:modelValue'],
    props: {
        data: {
            type: Array,
            required: true,
        },
        modelValue: {
            default: 0,
            required: false,
        },
        theme: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default: () => ({
                defaultValue: null,
            }),
        },
        label: {
            type: String,
            default: '',
        },
    },
    methods: {
        reset() {
            if (this.options.defaultValue !== null) {
                this._value = this.options.defaultValue;
            }
        },
    },
    computed: {
        _value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
        changed() {
            if (this.options.defaultValue !== null) {
                return this._value != this.options.defaultValue;
            }
            if (!Number.isNaN(this._value * 1)) {
                return this._value * 1;
            }
            return this._value;
        },
        selectedItemText() {
            var selectedValue = this._value;
            const item = this.data.find(function (e) {
                return e.value == selectedValue;
            });
            if (item) {
                return item.labelText || item.text;
            }
            return this.options.name;
        },
    },
};


