<template>
    <section class="inline large bg-default">
        <div class="grid fixed xs-grid-cols-1 gap-3">
            <div class="text-center">
                <h2 class="f3 text-bold">
                    {{molang('home.MovotoBotText')}}
                </h2>
            </div>
            <mvtCollapsibleLinks
                v-if="stateList && stateList.length"
                :numCols="4"
                titleTag="h3"
                :title="molang('home.ListingsByState')"
                :links="stateList"
            />
            <mvtCollapsibleLinks
                v-if="home.popularCityList && home.popularCityList.length"
                :numCols="4"
                titleTag="h3"
                :title="molang('home.PopularCities')"
                :links="home.popularCityList.map(item => ({ url: `${glb.appUrl}${item.url}`, text: `${ item.cityName.charAt(0).toUpperCase() + item.cityName.slice(1)}`}))"
            />
        </div>
    </section>
</template>

<script src="./home-links.js"></script>
