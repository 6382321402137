<template>
    <section>
        <div class="grid fixed xs-grid-cols-1">
            <template v-if="home?.seoNewListings">
                <div class="text-center">
                    <h2 class="f3 text-bold">
                        New Homes for Sale
                    </h2>
                </div>
                <mvtNearby :data="home?.seoNewListings" carousalType="nationwide_new"></mvtNearby>
            </template>
            <template v-else>
                <div class="text-center">
                    <h2 class="f3 text-bold">
                        {{molang(`home.NewestDesp${glb.rentLang}`)}} <a v-if="home.geo && home.geo.newPath" :href="glb.appUrl + home.geo.newPath" class="link">{{home.geo.city}}</a>
                    </h2>
                </div>
                <mvtLazyload ref="lazyload" :async="true" data-role="propertycard">
                    <mvtNearby type="city" filterPath="/single-family/new-7/" @completed="completed" carousalType="new"></mvtNearby>
                </mvtLazyload>
            </template>
        </div>
    </section>
</template>

<script src="./home-nearbynew.js"></script>
