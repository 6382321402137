var time;
export default {
    name: 'mvtInputGeo',
    inject: ['$eventBus'],
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Object,
            default: {
                text: '',
                geo: null,
            },
        },
        btnclass: {
            type: String,
            default: 'btn primary regular',
        },
        isShowLabel: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
        },
        hideButton: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default() {
                return {};
            },
        },
        addEventClick: {
            type: Function,
            default() {
                return () => {};
            },
        },
        autoSelect: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            gautocomplete: null,
            observerInstance: null,
        };
    },
    methods: {
        reset() {
            this._setText('');
            this._value = {
                text: '',
                geo: null,
            };
        },
        onClick(matched) {
            var that = this;
            var text = $(this.$refs.geo).val() || this.modelValue.text;
            // if search is clicked without any search text
            if (text !== '') {
                if (matched !== true) {
                    var closetInput = $('.pac-container').find('.pac-item');
                    var closetText = [];
                    if (closetInput.length > 0) {
                        closetInput = closetInput.eq(0).children();
                        closetInput.each(function () {
                            var e = $(this).text();
                            if (e) {
                                closetText.push(e);
                            }
                        });
                        var reg = new RegExp(text.substring(0, 5), 'i');
                        var suggestText = closetText.join(' ');
                        if (reg.test(suggestText)) {
                            text = suggestText;
                        }
                    }
                }

                $.geoServiceSearch(text).then(function (results) {
                    if (results && results.length) {
                        that._value = {
                            text: text,
                            geo: results[0],
                        };
                    } else {
                        that._value = {
                            text: '',
                            geo: null,
                        };
                    }
                    that.addEventClick();
                });
            }
        },
        initalGoogleAutoComplete() {
            var that = this;
            var el = this.$refs.geo;
            var opt = {
                types: this.getOptions.types,
                componentRestrictions: {
                    country: this.getOptions.country,
                },
                dataType: this.getOptions.dataType,
            };
            $.loadGMap().then(function () {
                var google = window.google;
                if (that.getOptions.boundsLat && that.getOptions.boundsLng) {
                    opt.bounds = new google.maps.Circle({
                        center: { lat: that.getOptions.boundsLat, lng: opt.boundsLng },
                        radius: that.getOptions.radius,
                    }).getBounds();
                } else {
                    opt.bounds = new google.maps.LatLngBounds(new google.maps.LatLng(-90, -180), new google.maps.LatLng(90, 180));
                }
                that.gautocomplete = new google.maps.places.Autocomplete(el, opt);
                that.gautocomplete.setFields(['utc_offset_minutes']);
                google.maps.event.addListener(that.gautocomplete, 'place_changed', function () {
                    var text = $(el).val();
                    var geo = that.gautocomplete.getPlace();
                    if (geo && geo.place_id) {
                        that._value = {
                            text: text,
                            geo: geo,
                        };
                    } else {
                        $.geoServiceSearch(text).then(function (results) {
                            if (results && results.length) {
                                that._value = {
                                    text: text,
                                    geo: results[0],
                                };
                            } else {
                                that._value = {
                                    text: text,
                                    geo: null,
                                };
                            }
                        });
                    }
                });
            });
        },
        _setText: function (text) {
            $(this.$refs.geo).val(text);
        },
        observeItemUpdate: function () {
            var target = $('.pac-container')[0];
            target && this.observerInstance.observe(target, { childList: true });
        },
    },
    computed: {
        getOptions() {
            return Object.assign(
                {
                    delayLoad: 0,
                    boundsLat: null,
                    boundsLng: null,
                    radius: 10000,
                    dataType: 'address',
                    country: 'us',
                    types: ['geocode'],
                },
                this.options
            );
        },
        _value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                if (time) {
                    clearTimeout(time);
                }
                setTimeout(() => {
                    this.$emit('update:modelValue', value);
                    // this.$el.dataset.value = value;
                    this.$el.dataset.text = value.text;
                    this.$el.dataset.geo = JSON.stringify(value.geo);
                    const event = new Event('change');
                    this.$el.dispatchEvent(event);
                }, 1);
            },
        },
    },
    mounted() {
        var that = this;
        var el = this.$refs.geo;
        var scrollY = $.scrollParent(el);
        if (scrollY && !$(scrollY).is('body')) {
            $(el).on('input', function () {
                var pacContainer = $('.pac-container');
                if (pacContainer && pacContainer.length > 0 && $(this).siblings('.pac-container').length === 0) {
                    $(this).parent().append(pacContainer);
                }
            });
        }
        if(this.modelValue.text) {
            this.onClick();
        }
        //cw-7113 hackin code from https://stackoverflow.com/questions/7865446/google-maps-places-api-v3-autocomplete-select-first-option-on-enter
        var _addEventListener = el.addEventListener ? el.addEventListener : el.attachEvent;
        function addEventListenerWrapper(type, listener) {
            // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected,
            // and then trigger the original listener.
            if (type == 'keydown') {
                var origListener = listener;
                that.observeItemUpdate();
                listener = function (event) {
                    var suggestionSelected = $('.pac-item-selected').length > 0;
                    if ([13, 9].indexOf(event.which) >= 0 && !suggestionSelected) {
                        if ($('.pac-container').find('.pac-item').length > 0) {
                            var simulatedDownarrow = new KeyboardEvent('keydown', {
                                keyCode: 40,
                                which: 40,
                            });
                            origListener.apply(el, [simulatedDownarrow]);
                            if (event.which === 9) {
                                el.dispatchEvent(new CustomEvent('change'));
                                event.preventDefault();
                            }
                        }
                    }

                    origListener.apply(el, [event]);
                };
            }
            _addEventListener.apply(el, [type, listener]);
        }
        el.addEventListener = addEventListenerWrapper;
        el.attachEvent = addEventListenerWrapper;
        //hackin code end
        $(el).on('keyup.geo', function (e) {
            if (e.key === 'Enter') {
                that.onClick(true);
            }
        });
        if (this._value.text) {
            this._setText(this._value.text);
        }
        $(el).on('keyup.inital', function (e) {
            var text = $(this).val();
            if (text.length > that.getOptions.delayLoad) {
                that.initalGoogleAutoComplete(e);
                $(el).off('keyup.inital');
            }
        });
        if (this.autoSelect) {
            $(el).on('focusout.geo', () => {
                var text = $(this.$refs.geo).val() || this.modelValue.text;
                if (text && !this._value.geo) {
                    that.onClick(true);
                }
            });
        }
        this.observerInstance = new MutationObserver(function () {
            var pacList = $('.pac-container').find('.pac-item');
            pacList.each(function (index, item) {
                var pacItemText = $(item).find('span:not([class])')[0];
                $(pacItemText).text($(pacItemText).text().replace(', USA', ''));
            });
        });
        this.$eventBus.$on('eventbus-reset-inputgeo', this.reset);
    },
    beforeUnmount() {
        $(this.$refs.geo).off('keyup.inital');
        $(this.$refs.geo).off('keyup.geo');
        this.$eventBus.$off('eventbus-reset-inputgeo');
        this.observerInstance.disconnect();
    },
};
